import { useMemo } from 'react'
import { GetPriceQueryResult } from '../../graphql/queries/getPrice.query'
import { FormValue, FormDefinition } from '../SchemaForm/SchemaForm.model'
import { FormWrapper } from '../SchemaForm/SchemaForm.styled'

const PaymentSubmitForm: React.FC<{
  currentFormValue?: FormValue
  priceQueryData?: GetPriceQueryResult
  customerId?: string
  orderId?: string
  paymentId?: string
  children?: (JSX.Element | undefined)[]
  formDefinition: FormDefinition
  isSummaryPage: boolean
  handleSubmit: () => void
}> = ({
  currentFormValue,
  priceQueryData,
  customerId = '',
  orderId = '',
  paymentId = '',
  children,
  formDefinition,
  isSummaryPage,
  handleSubmit,
}) => {
  const returnUrl = useMemo(
    () =>
      customerId &&
      orderId &&
      `${process.env.REACT_APP_ORIGIN_URL}/view-order/${customerId}/${orderId}`,
    [customerId, orderId],
  )

  if (
    currentFormValue &&
    priceQueryData &&
    formDefinition.paymentMethod === 'PAY_SOLUTION' &&
    isSummaryPage
  ) {
    const collectionName =
      formDefinition.summaryFields.find(item => item.name === 'collection')?.properties
        ?.readonlyInputValue || ''

    return (
      <FormWrapper method="post" action="https://www.thaiepay.com/epaylink/payment.aspx">
        <input type="hidden" name="refno" value={paymentId} />
        <input type="hidden" name="merchantid" value="50636016" />
        <input type="hidden" name="customeremail" value={currentFormValue.email} />
        <input type="hidden" name="cc" value="00" />
        <input type="hidden" name="productdetail" value={`${orderId}|${collectionName}`} />
        <input type="hidden" name="total" value={priceQueryData.data?.getPrice.total} />
        <input
          type="hidden"
          name="customername"
          value={`${currentFormValue.customerFirstname} ${currentFormValue.customerLastname}`}
        />
        <input type="hidden" name="returnurl" value={returnUrl} />
        {children}
      </FormWrapper>
    )
  }

  return <FormWrapper onSubmit={handleSubmit}>{children}</FormWrapper>
}

export default PaymentSubmitForm
