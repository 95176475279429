import Button from '@mui/material/Button/Button'
import styled from 'styled-components'

export const UploadButton = styled(Button)`
  gap: 0.5em;
  font-weight: normal !important;
  border-radius: 8px !important;
  color: #1b879d !important;
  background-color: white !important;
  box-shadow: unset !important;
  width: 10em;
  align-self: center !important;

  &:hover {
    color: #125e6d !important;
  }
`

export const PreviewImage = styled.img`
  @media only screen and (min-width: 768px) {
    width: 13.125em;
  }
`
