import Radio from '@mui/material/Radio/Radio'
import styled from 'styled-components'

export const RadioIcon = styled.div<{ checked?: boolean }>`
  width: 1em;
  height: 1em;
  background-color: white;
  border: 1px solid #d0d5dd;
  border-radius: 50%;

  ${props =>
    props.checked &&
    `border: 1px solid #931720;
    background-color: #931720;
    &:after {
      content: ' ';
      position: absolute;
      width: 8px;
      height 8px;
      border-radius: 50%;
      background-color: white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }`}
`

export const FormRadio = styled(Radio)`
  padding: 4px !important;
  margin: 0 -4px;
`
