import React from 'react'
import { FilloutFullScreenEmbed } from '@fillout/react'
import '@fillout/react/style.css'
import { FlexGap } from '../../App.styled'

const MooNagaPage: React.FC = () => {
  return (
    <div>
      <FlexGap
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ backgroundColor: '#931720', color: 'white', height: '100vh' }}
      >
        <FilloutFullScreenEmbed filloutId="aZ5R6QiiYNus" />
      </FlexGap>
    </div>
  )
}

export default MooNagaPage
