import Modal from '@mui/material/Modal/Modal'
import React from 'react'
import { FlexGap, AppText, AppPrimaryButton } from '../../App.styled'
import { ModalWrapper } from '../AppModal/AppModal.styled'
import { FailureModalProps } from './FailureModal.model'

const FailureModal: React.FC<FailureModalProps> = ({
  open = false,
  title = 'คำสั่งซื้อล้มเหลว!',
  children,
  onClose,
}) => (
  <Modal open={open} onClose={onClose}>
    <ModalWrapper style={{ alignItems: 'center' }}>
      <FlexGap flexDirection="column" alignItems="center">
        <FlexGap flexDirection="column" alignItems="center" gap="3em">
          <img
            src="/images/failure-order.svg"
            alt="Failure Order"
            width="66"
            style={{ paddingTop: '2em' }}
          />
          <AppText fontSize="1.25em" fontWeight="500">
            {title}
          </AppText>
        </FlexGap>
        <AppText style={{ textAlign: 'center', maxWidth: 'calc(100vw - 7em)' }}>
          {children || (
            <>
              มีข้อผิดพลาดเกิดขึ้นกับคำสั่งซื้อของท่าน กรุณาติดต่อแอดมินผ่านทาง{' '}
              <div>Line @mootaeworld</div>
            </>
          )}
        </AppText>
      </FlexGap>
      <AppPrimaryButton variant="contained" onClick={onClose} style={{ marginTop: '3em' }}>
        ลองใหม่อีกครั้ง
      </AppPrimaryButton>
    </ModalWrapper>
  </Modal>
)

export default FailureModal
