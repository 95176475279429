import styled from 'styled-components'

export const FormSelect = styled.select<{ hasValue?: boolean; error?: boolean }>`
  background-color: white;
  font-size: 1rem;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 20px;
  padding: 0.625em 1em;
  cursor: pointer;
  outline: none;
  appearance: none;
  background-image: url('icons/chevron-down-icon.svg');
  background-repeat: no-repeat, repeat;
  background-position: right 0.75em top 50%, 0 0;
  font-family: 'LINESeedSans', sans-serif;

  ${props => !props.hasValue && 'color: #667085;'}
  ${props => props.error && 'border: 1px solid #FDA29B;'}
`
