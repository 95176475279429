import React, { useMemo, useRef, useState } from 'react'
import { FormUploadImageProps, UploadInputElement } from './FormUploadImage.model'
import { AppPrimaryButton, FlexGap } from '../../App.styled'
import { PreviewImage } from './FormUploadImage.styled'

const convertFileToBase64 = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const FormUploadImage: React.FC<FormUploadImageProps> = ({
  formField,
  formId,
  value,
  onChange,
}) => {
  const [previewImageSrc, setPreviewImageSrc] = useState(value)
  const uploadInputRef = useRef<UploadInputElement | null>(null)

  const handleOnClick = () => {
    if (uploadInputRef?.current) {
      uploadInputRef.current.click()
    }
  }

  const handleInputFileOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files

    if (newFiles && newFiles.length > 0) {
      convertFileToBase64(newFiles[0]).then(result => {
        if (!result) {
          return
        }

        const newValue = result as string
        setPreviewImageSrc(newValue)
        onChange(newValue)
      })
    }

    if (uploadInputRef?.current) {
      uploadInputRef.current.value = ''
    }
  }

  const renderUploadPreview = useMemo(() => {
    return previewImageSrc ? (
      <PreviewImage
        onClick={handleOnClick}
        src={previewImageSrc}
        width="160"
        alt="Preview Upload"
        aria-hidden="true"
        style={{ alignSelf: 'center' }}
      />
    ) : (
      <FlexGap justifyContent="center">
        <AppPrimaryButton
          id={formId}
          variant="contained"
          sx={{ maxWidth: '8.75rem' }}
          onClick={handleOnClick}
        >
          <FlexGap gap="0.5em" alignItems="center">
            <img src="/icons/add-photo-icon.svg" alt="Add" width="16" />
            <span>{formField.properties?.uploadButtonText || 'อัพโหลดไฟล์'}</span>
          </FlexGap>
        </AppPrimaryButton>
      </FlexGap>
    )
  }, [formField.properties?.uploadButtonText, formId, previewImageSrc])

  return (
    <>
      {renderUploadPreview}
      <input
        ref={uploadInputRef}
        onChange={handleInputFileOnChange}
        accept="image/*"
        id="icon-button-file"
        type="file"
        style={{ display: 'none' }}
      />
    </>
  )
}

export default FormUploadImage
