import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { API, graphqlOperation } from 'aws-amplify'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import {
  AppContainer,
  AppDivider,
  AppFormCard,
  AppPrimaryButton,
  AppPrimaryText,
  AppText,
  FlexGap,
  LoadingOverlay,
} from '../App.styled'
import {
  FormControlWrapper,
  FormErrorMessage,
  FormTextInput,
  FormTextInputWrapper,
} from '../components/SchemaForm/SchemaForm.styled'
import {
  GetOrdersByCustomer,
  GetOrdersByCustomerResult,
  SearchOrderResult,
} from '../graphql/queries/getOrdersByCustomer.query'
import { DateUtils } from '../utils/date.utils'
import { ViewOrderFlexColumn, ViewOrderLabel, ViewOrderText } from './ViewOrder.styled'

export const SearchOrderPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<SearchOrderResult[] | null>(null)
  const { control, handleSubmit, formState } = useForm<{ email: string }>({ mode: 'onChange' })
  const navigate = useNavigate()

  const handleSearchOrder = async (value: { email: string }) => {
    try {
      setIsLoading(true)
      const result = (await API.graphql(
        graphqlOperation(GetOrdersByCustomer, value),
      )) as GetOrdersByCustomerResult

      const filteredList = result.data.getOrdersByCustomer.filter(
        item =>
          item.internal_order_status !== 'WAITING-FOR-PAYMENT' &&
          item.internal_order_status !== 'DELETED',
      )

      setOrders(filteredList)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleNavigateViewOrder = (customerId: string, orderId: string) => () => {
    navigate(`/view-order/${customerId}/${orderId}`)
  }

  const renderOrderResultCards = orders && orders.length > 0 && (
    <FlexGap flexDirection="column" gap="1.5em" width="100%">
      {orders.map(order => (
        <AppFormCard key={order.order_id}>
          <FlexGap flexDirection="column">
            <ViewOrderFlexColumn>
              <ViewOrderLabel>หมายเลขคำสั่งซื้อ</ViewOrderLabel>
              <ViewOrderText>{order.order_id}</ViewOrderText>
            </ViewOrderFlexColumn>
            <ViewOrderFlexColumn>
              <ViewOrderLabel>วันที่สั่งซื้อ</ViewOrderLabel>
              <ViewOrderText>
                {DateUtils.beYearFormat(order.date_created, 'YYYY/MM/DD HH:mm:ss')}
              </ViewOrderText>
            </ViewOrderFlexColumn>

            <AppDivider />

            <ViewOrderFlexColumn>
              <ViewOrderLabel>คอลเลคชัน Wallpaper</ViewOrderLabel>
              <ViewOrderText wordBreak="break-word">{order.collection}</ViewOrderText>
            </ViewOrderFlexColumn>

            {order.template &&
              [
                'Star Tarot',
                'Devas Tarot',
                'Arcana - Desktop',
                'Arcana - Mobile',
                'Asura Rahu',
                'เทพเซียน Collection',
                'การ์ดเสริมดวงเทพพกพา',
                'เทพเซียน Collection 2',
                'มูรติเทวา Tarot',
              ].includes(order.collection) && (
                <ViewOrderFlexColumn>
                  <ViewOrderLabel>แบบที่ต้องการเลือก</ViewOrderLabel>
                  <ViewOrderText>{order.template}</ViewOrderText>
                </ViewOrderFlexColumn>
              )}

            <AppPrimaryButton
              variant="contained"
              type="button"
              style={{ width: '100%', gap: '1em', marginTop: '1em' }}
              onClick={handleNavigateViewOrder(order.customer_id, order.order_id)}
            >
              รายละเอียดเพิ่มเติม
              <img src="/icons/chevron-right-icon.svg" alt="chevron-right" />
            </AppPrimaryButton>
          </FlexGap>
        </AppFormCard>
      ))}
    </FlexGap>
  )

  return (
    <AppContainer>
      <FlexGap
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        style={{ minHeight: 'calc(100vh - 3em)' }}
      >
        <FlexGap flexDirection="column" gap="1.5em" alignItems="center" width="100%">
          <img src="/logos/mootaeworld-logo.svg" alt="Mootaeworld Logo" width="120" />
          <AppPrimaryText fontWeight="700" fontSize="1.5rem">
            ตรวจสอบสถานะคำสั่งซื้อของคุณ
          </AppPrimaryText>
          <form
            onSubmit={handleSubmit(handleSearchOrder)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '2em',
              width: '100%',
            }}
          >
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'กรุณากรอกอีเมล์ให้ถูกต้อง',
                },
              }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <FormControlWrapper style={{ width: '100%', textAlign: 'center' }}>
                  <FormTextInputWrapper error={!!error}>
                    <FormTextInput
                      error={!!error}
                      type="email"
                      style={{ border: '1px solid #D0D5DD', textAlign: 'center' }}
                      placeholder="กรุณากรอกอีเมล"
                      inputMode="email"
                      onChange={e => onChange(e.target.value)}
                    />
                  </FormTextInputWrapper>
                  {error && error.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControlWrapper>
              )}
            />

            <AppPrimaryButton
              variant="contained"
              type="submit"
              fullWidth
              disabled={!formState.isValid}
            >
              ค้นหา
            </AppPrimaryButton>

            {renderOrderResultCards}
          </form>
        </FlexGap>
        {orders && orders.length === 0 && <AppText color="#667085">ไม่พบคำสั่งซื้อของท่าน</AppText>}
      </FlexGap>
      {isLoading && (
        <LoadingOverlay>
          <CircularProgress sx={{ color: '#C2442A' }} />
        </LoadingOverlay>
      )}
    </AppContainer>
  )
}

export default SearchOrderPage
