import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { API, graphqlOperation } from 'aws-amplify'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppContainer,
  AppDivider,
  AppPrimaryButton,
  AppPrimaryText,
  AppText,
  FlexGap,
  LoadingOverlay,
} from '../App.styled'
import { GetOrderQuery, GetOrderQueryResult } from '../graphql/queries/getOrder.query'
import { DateUtils } from '../utils/date.utils'
import { NumberUtils } from '../utils/number.utils'
import {
  ViewOrderCard,
  ViewOrderLabel,
  ViewOrderText,
  ViewOrderFlexColumn,
} from './ViewOrder.styled'

const mapSpellTemplateImage = (template: string, collection: string) => {
  const spellTemplateImage: { [key: string]: string } = {
    พระพิฆเนศ:
      collection === 'มูรติเทวา Tarot'
        ? '/images/murathi-ganesha-spell.jpg'
        : '/images/ganesha-spell.png',
    พระแม่ลักษมี:
      collection === 'มูรติเทวา Tarot'
        ? '/images/murathi-lakshmi-spell.jpg'
        : '/images/lakshmi-spell.png',
    พระศิวะ: '/images/shiva-spell.png',
    พระตรีมูรติ: '/images/trimurti-spell.png',
    พระราหู: '/images/rahu-spell.png',
    พระแม่กาลี: '/images/murathi-kali-spell.jpg',
    พระแม่ทุรคา: '/images/murathi-durga-spell.jpg',
    พระพรหม: '/images/murathi-brahma-spell.jpg',
    เทพเจ้าเห้งเจีย: '/images/wukong-spell.jpg',
    เจ้าแม่กวนอิม: '/images/guanim-spell.jpg',
    เทพไฉ่ซิงเอี๊ย: '/images/god-of-wealth-spell.jpg',
    เทพกวนอู: '/images/guanyu-spell.jpg',
    เซียนจิ้งจอก: '/images/fox-goddess-spell.jpg',
    เจ้าแม่ทับทิม: '/images/tubtim-spell.jpg',
  }

  return spellTemplateImage[template] || '/images/asura-rahu-spell.png'
}

const isRedeemOrder = (collection: string) => collection === 'The 1 Today x มูเตเวิร์ล'

const ViewOrderPage: React.FC = () => {
  const { customerId, orderId } = useParams()

  const [orderQueryResult, setOrderQueryResult] = useState<GetOrderQueryResult>()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const orderData = useMemo(
    () => (orderQueryResult ? orderQueryResult.data.getOrder : undefined),
    [orderQueryResult],
  )

  const fetchOrderQuery = useCallback(async () => {
    setIsLoading(true)
    const result = (await API.graphql(
      graphqlOperation(GetOrderQuery, {
        customerId,
        orderId,
      }),
    )) as GetOrderQueryResult

    setOrderQueryResult({ ...result })
    setIsLoading(false)
  }, [customerId, orderId])

  const addOnsItems = useMemo(
    () => orderData?.itemized_pricing.items.filter(item => item.type === 'add-ons'),
    [orderData?.itemized_pricing.items],
  )

  const renderAddOns = useMemo(
    () =>
      addOnsItems &&
      addOnsItems.length > 0 && (
        <>
          <AppDivider />

          <AppText fontWeight="700">บริการเสริม</AppText>

          {addOnsItems.map(item => (
            <AppText key={`add-on-${item.item_name}-${item.price}`} style={{ flex: 1 }}>
              {item.item_name}
            </AppText>
          ))}
        </>
      ),
    [addOnsItems],
  )

  const renderPriceSummary = useMemo(
    () =>
      orderData &&
      !isRedeemOrder(orderData.collection) && (
        <>
          <AppDivider />

          <AppText fontWeight="700">ยอดการสั่งซื้อวอลเปเปอร์</AppText>

          <ViewOrderFlexColumn>
            <ViewOrderLabel>รวมทั้งหมด</ViewOrderLabel>
            <ViewOrderText fontWeight="700">
              {NumberUtils.mapCurrencyText(
                'THB',
                NumberUtils.numberWithComma(orderData.itemized_pricing.total),
              )}
            </ViewOrderText>
          </ViewOrderFlexColumn>
        </>
      ),
    [orderData],
  )

  const renderGodSpell = useMemo(
    () =>
      orderData &&
      orderData.collection &&
      [
        'Devas Tarot',
        'เทพเซียน Collection',
        'เทพเซียน Collection 2',
        'การ์ดเสริมดวงเทพพกพา',
        'มูรติเทวา Tarot',
      ].includes(orderData.collection) && (
        <ViewOrderCard>
          <AppPrimaryText
            variant="primary"
            fontSize="1.5rem"
            fontWeight="700"
            style={{ textAlign: 'center' }}
          >
            บทสวด
          </AppPrimaryText>
          <img src={mapSpellTemplateImage(orderData.template, orderData.collection)} alt="spell" />

          <a
            href={mapSpellTemplateImage(orderData.template, orderData.collection)}
            download
            style={{
              textDecoration: 'none',
              width: '100%',
              maxWidth: '20em',
              margin: 'auto',
            }}
          >
            <AppPrimaryButton variant="contained" style={{ width: '100%', margin: '1em 0' }}>
              คลิก เพื่อรับ บทสวดมนต์
            </AppPrimaryButton>
          </a>
          <AppText>
            <b>หมายเหตุ: </b>
            หากคุณลูกค้าไม่สามารถกดบันทึกรูปบทสวดได้ กรุณาคัดลอกลิงค์นี้และเปิดใน Google Chrome แทน
          </AppText>
        </ViewOrderCard>
      ),
    [orderData],
  )

  useEffect(() => {
    fetchOrderQuery()
  }, [fetchOrderQuery])

  return (
    <div style={{ backgroundColor: '#F8F8F8' }}>
      <AppContainer style={{ padding: '.625em 1em' }}>
        <FlexGap flexDirection="column" alignItems="center" style={{ position: 'relative' }}>
          <img
            src="/icons/chevron-left-icon.svg"
            alt="chevron-right"
            style={{
              padding: '0.5em',
              margin: '0 -0.5em',
              cursor: 'pointer',
              alignSelf: 'start',
            }}
            aria-hidden="true"
            onClick={() => navigate(-1)}
          />
          <AppPrimaryText fontSize="1.25rem" style={{ alignSelf: 'start' }}>
            รายละเอียดคำสั่งซื้อของคุณ
          </AppPrimaryText>
          <AppPrimaryText
            variant="primary"
            fontSize="1rem"
            fontWeight="450"
            style={{ alignSelf: 'start' }}
          >
            คำสั่งซื้อได้เข้าสู่ระบบเรียบร้อย โปรดตรวจสอบอีเมลของท่านและเก็บ Link นี้ไว้ในการรับ
            Wallpaper ภายหลัง
          </AppPrimaryText>

          {orderData && (
            <>
              <ViewOrderCard>
                <ViewOrderFlexColumn>
                  <ViewOrderLabel>หมายเลขคำสั่งซื้อ</ViewOrderLabel>
                  <ViewOrderText fontWeight="700">{orderData?.order_id}</ViewOrderText>
                </ViewOrderFlexColumn>

                <ViewOrderFlexColumn>
                  <ViewOrderLabel>วันที่สั่งซื้อ</ViewOrderLabel>
                  <ViewOrderText>
                    {DateUtils.beYearFormat(orderData?.date_created, 'YYYY/MM/DD HH:mm:ss')}
                  </ViewOrderText>
                </ViewOrderFlexColumn>

                {renderPriceSummary}

                <ViewOrderFlexColumn>
                  <ViewOrderLabel>สถานะการชำระเงิน</ViewOrderLabel>
                  <ViewOrderText>
                    {orderData.internal_order_status !== 'WAITING-FOR-PAYMENT'
                      ? 'ชำระเงินแล้ว'
                      : 'รอการชำระเงิน'}
                  </ViewOrderText>
                </ViewOrderFlexColumn>
              </ViewOrderCard>

              <AppPrimaryText fontSize="1.25rem" style={{ alignSelf: 'start' }}>
                รายละเอียดวอลเปเปอร์
              </AppPrimaryText>

              <ViewOrderCard>
                <AppText fontWeight="700">ข้อมูลส่วนตัว</AppText>

                <ViewOrderFlexColumn>
                  <ViewOrderLabel>ชื่อ-นามสกุล</ViewOrderLabel>
                  <ViewOrderText>
                    {orderData.customer_firstname} {orderData.customer_lastname}
                  </ViewOrderText>
                </ViewOrderFlexColumn>

                <ViewOrderFlexColumn>
                  <ViewOrderLabel>เบอร์โทรศัพท์</ViewOrderLabel>
                  <ViewOrderText>{orderData.telephone}</ViewOrderText>
                </ViewOrderFlexColumn>

                <ViewOrderFlexColumn>
                  <ViewOrderLabel>อีเมล</ViewOrderLabel>
                  <ViewOrderText>{orderData.email}</ViewOrderText>
                </ViewOrderFlexColumn>

                <AppDivider />

                <ViewOrderFlexColumn flexDirection="column">
                  <AppText fontWeight="700">ช่องทางการสั่งซื้อ</AppText>
                  <ViewOrderFlexColumn alignItems="center">
                    <img
                      src={`/images/social-${orderData.sale_channel}.svg`}
                      alt={`social-${orderData.sale_channel}`}
                      height="32"
                    />
                    <span>{orderData.sale_username}</span>
                  </ViewOrderFlexColumn>
                </ViewOrderFlexColumn>

                <AppDivider />

                <AppText fontWeight="700">ข้อมูลคอลเลคชันวอลเปเปอร์</AppText>

                <ViewOrderFlexColumn>
                  <ViewOrderLabel>คอลเลคชัน Wallpaper</ViewOrderLabel>
                  <ViewOrderText>{orderData.collection}</ViewOrderText>
                </ViewOrderFlexColumn>

                {orderData.template &&
                  [
                    'Star Tarot',
                    'Devas Tarot',
                    'Arcana - Desktop',
                    'Arcana - Mobile',
                    'Asura Rahu',
                    'เทพเซียน Collection',
                    'การ์ดเสริมดวงเทพพกพา',
                    'เทพเซียน Collection 2',
                  ].includes(orderData.collection) && (
                    <ViewOrderFlexColumn>
                      <ViewOrderLabel>แบบที่ต้องการเลือก</ViewOrderLabel>
                      <ViewOrderText>{orderData.template}</ViewOrderText>
                    </ViewOrderFlexColumn>
                  )}

                {orderData.choice && (
                  <ViewOrderFlexColumn>
                    <ViewOrderLabel>เรื่องที่ต้องการเสริม</ViewOrderLabel>
                    <ViewOrderText>{orderData.choice}</ViewOrderText>
                  </ViewOrderFlexColumn>
                )}

                {orderData.sub_choice_1 && (
                  <ViewOrderFlexColumn>
                    <ViewOrderLabel>สิ่งที่ต้องการเสริม</ViewOrderLabel>
                    <ViewOrderText>{orderData.sub_choice_1}</ViewOrderText>
                  </ViewOrderFlexColumn>
                )}

                {orderData.sub_choice_2 && (
                  <ViewOrderFlexColumn>
                    <ViewOrderLabel>สิ่งที่ต้องการเสริม (เพิ่มเติม)</ViewOrderLabel>
                    <ViewOrderText>{orderData.sub_choice_2}</ViewOrderText>
                  </ViewOrderFlexColumn>
                )}

                {renderAddOns}
              </ViewOrderCard>

              {orderData.collection === 'เทพเซียน Collection 2' && orderData.lucky_number && (
                <ViewOrderCard>
                  <AppPrimaryText
                    variant="primary"
                    fontSize="1.5rem"
                    fontWeight="700"
                    style={{ textAlign: 'center' }}
                  >
                    เลขมูเสริมดวงปัง
                  </AppPrimaryText>

                  {orderData.order_status === 'DONE' ? (
                    <>
                      <AppPrimaryText
                        variant="primary"
                        fontSize="3rem"
                        fontWeight="800"
                        style={{ textAlign: 'center' }}
                      >
                        {orderData.lucky_number.number}
                      </AppPrimaryText>

                      <ViewOrderText>{orderData.lucky_number.definition}</ViewOrderText>
                    </>
                  ) : (
                    <AppText color="#F04438" style={{ textAlign: 'center' }}>
                      ขออภัย <br /> เลขมูเสริมดวงปังของคุณกำลังอยู่ระหว่างดำเนินการ
                    </AppText>
                  )}
                </ViewOrderCard>
              )}

              {orderData.order_status === 'DONE' && (
                <>
                  <ViewOrderCard>
                    <AppPrimaryText
                      variant="primary"
                      fontSize="1.5rem"
                      fontWeight="700"
                      style={{ textAlign: 'center' }}
                    >
                      วอลเปเปอร์ของคุณ
                    </AppPrimaryText>
                    <ViewOrderText>
                      การแสดงผลของวอลเปเปอร์แต่ละคนอาจไม่ เหมือนกัน บางคนอาจแสดงผลในเวลาไม่นานหรือ
                      สำหรับบางคนอาจอยู่ที่ 3-6 เดือน เพื่อให้เวลา
                      พลังไพ่ได้ทำงานกับดวงของคุณลูกค้าค่ะ
                    </ViewOrderText>
                    <ViewOrderText>
                      สำหรับคุณลูกค้าที่เลือกแผ่นดวงแผ่นดวง (รูปลูกโลก) ที่ใส่ในวอลเปเปอร์ของ
                      คุณลูกค้าเป็นพิกัด กำเนิดที่พล็อตจากดวงเกิด ของคุณลูกค้าวางบน แผนที่โลกอีกที
                      เนื่องจาก การที่เอาดวง (ที่มีดาวทั้งหมดไปใส่เลย) ตามหลักโหรแล้ว คือ การโชว์
                      ดวงให้คนอื่นเห็น จะทำให้คนอื่นรู้ดวงเราทั้งหมด ทางเราจึงขอใส่
                      เป็นพิกัดดาวลงไปแทน เพื่อความ เป็นส่วนตัว ของดวงคุณลูกค้าแน่นอนว่าตามพิกัด
                      แบบนี้ ยังคงเป็นแผ่นดวงของลูกค้าอยู่เช่นเดิม
                    </ViewOrderText>

                    {orderData.start_time_use_datetime && (
                      <>
                        <AppText fontWeight="700" style={{ textAlign: 'center' }}>
                          ฤกษ์มงคลในการเริ่มใช้ Wallpaper ของคุณ
                        </AppText>
                        <FlexGap justifyContent="center">
                          <AppPrimaryText variant="primary" fontSize="1.5rem">
                            วันที่{' '}
                            {DateUtils.beYearFormat(
                              orderData.start_time_use_datetime,
                              undefined,
                              'DD/MM/YYYY',
                            )}
                          </AppPrimaryText>

                          <AppPrimaryText variant="primary" fontSize="1.5rem">
                            เวลา{' '}
                            {DateUtils.beYearFormat(
                              orderData.start_time_use_datetime,
                              undefined,
                              'HH:mm',
                            )}
                          </AppPrimaryText>
                        </FlexGap>
                      </>
                    )}

                    {orderData.img_url_1 && (
                      <a
                        href={orderData.img_url_1}
                        download
                        style={{
                          textDecoration: 'none',
                          width: '100%',
                          maxWidth: '20em',
                          margin: 'auto',
                        }}
                      >
                        <AppPrimaryButton
                          variant="contained"
                          style={{ width: '100%', margin: '1em 0' }}
                        >
                          คลิก เพื่อรับ Wallpaper
                        </AppPrimaryButton>
                      </a>
                    )}

                    <AppText>
                      <b>หมายเหตุ: </b>
                      หากคุณลูกค้าไม่สามารถกดบันทึกรูปวอลเปเปอร์ได้ กรุณาคัดลอกลิงค์นี้และเปิดใน
                      Google Chrome แทน และถ้าคุณใช้วอลเปเปอร์ตามฤกษ์ที่ให้ไว้
                      ไม่ทันและต้องการฤกษ์ใหม่ จะมีค่าบริการเพิ่มอีก 50 บาท
                    </AppText>
                  </ViewOrderCard>

                  {renderGodSpell}
                </>
              )}

              {orderData.order_status === 'PENDING' && (
                <>
                  <ViewOrderCard>
                    <AppPrimaryText
                      variant="primary"
                      fontSize="1.5rem"
                      fontWeight="700"
                      style={{ textAlign: 'center' }}
                    >
                      วอลเปเปอร์ของคุณ
                    </AppPrimaryText>
                    <ViewOrderText>
                      การแสดงผลของวอลเปเปอร์แต่ละคนอาจไม่ เหมือนกัน บางคนอาจแสดงผลในเวลาไม่นานหรือ
                      สำหรับบางคนอาจอยู่ที่ 3-6 เดือน เพื่อให้เวลา
                      พลังไพ่ได้ทำงานกับดวงของคุณลูกค้าค่ะ
                    </ViewOrderText>

                    <ViewOrderText>
                      สำหรับคุณลูกค้าที่เลือกแผ่นดวงแผ่นดวง (รูปลูกโลก) ที่ใส่ในวอลเปเปอร์ของ
                      คุณลูกค้าเป็นพิกัด กำเนิดที่พล็อตจากดวงเกิด ของคุณลูกค้าวางบน แผนที่โลกอีกที
                      เนื่องจาก การที่เอาดวง (ที่มีดาวทั้งหมดไปใส่เลย) ตามหลักโหรแล้ว คือ การโชว์
                      ดวงให้คนอื่นเห็น จะทำให้คนอื่นรู้ดวงเราทั้งหมด ทางเราจึงขอใส่
                      เป็นพิกัดดาวลงไปแทน เพื่อความ เป็นส่วนตัว ของดวงคุณลูกค้าแน่นอนว่าตามพิกัด
                      แบบนี้ ยังคงเป็นแผ่นดวงของลูกค้าอยู่เช่นเดิม
                    </ViewOrderText>

                    {!['การ์ดเสริมดวงพกพา', 'การ์ดเสริมดวงเทพพกพา'].includes(
                      orderData.collection,
                    ) && (
                      <AppText color="#F04438" style={{ textAlign: 'center' }}>
                        ขออภัย <br /> Wallpaper ของคุณกำลังอยู่ระหว่างดำเนินการ <br /> โปรดเก็บ Link
                        นี้ไว้ และกลับเข้ามาอีกครั้งในภายหลังเพื่อรับ Wallpaper ของคุณ
                      </AppText>
                    )}
                  </ViewOrderCard>
                  {renderGodSpell}
                </>
              )}
            </>
          )}
        </FlexGap>
      </AppContainer>
      {isLoading && (
        <LoadingOverlay>
          <CircularProgress sx={{ color: '#C2442A' }} />
        </LoadingOverlay>
      )}
    </div>
  )
}

export default ViewOrderPage
