import { CircularProgress } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppGradientWrapper, AppContainer, LoadingOverlay, AppFormFlex } from '../App.styled'

const GradientLayout: React.FC<{ isLoading?: boolean; hideLogo?: boolean }> = ({
  isLoading,
  hideLogo,
}) => {
  return (
    <AppGradientWrapper>
      <AppContainer>
        {isLoading && (
          <LoadingOverlay>
            <CircularProgress sx={{ color: '#C2442A' }} />
          </LoadingOverlay>
        )}
        <AppFormFlex>
          {!hideLogo && (
            <img src="/logos/mootaeworld-logo.svg" alt="Mootaeworld Logo" width="100" />
          )}
          <Outlet />
        </AppFormFlex>
      </AppContainer>
    </AppGradientWrapper>
  )
}

export default GradientLayout
