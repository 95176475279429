// import moment from 'moment'
import moment from 'moment-timezone'
import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { FlexGap } from '../../App.styled'
import { FormDateSelectGroupProps } from './FormDateSelectGroup.model'
import { FormSelect } from './FormDateSelectGroup.styled'

const selectDefaultValue = 'DEFAULT'

const renderDayOptions = (dayInMonths: number, minDay = 1) =>
  Array.from({ length: dayInMonths - minDay + 1 }, (_, index) => (
    <option key={`day-${index}`} value={(minDay + index).toString().padStart(2, '0')}>
      {(minDay + index).toString().padStart(2, '0')}
    </option>
  ))

const renderMonthOptions = (numberOfMonths = 12, minMonth = 1) =>
  Array.from({ length: numberOfMonths - minMonth + 1 }, (_, index) => (
    <option key={`month-${index}`} value={(minMonth + index).toString().padStart(2, '0')}>
      {(minMonth + index).toString().padStart(2, '0')}
    </option>
  ))

const renderYearOptions = (startYear: string, endYear: string) =>
  Array.from({ length: Number(endYear) - Number(startYear) + 1 }, (_, index) => (
    <option key={`year-${startYear + index}`} value={Number(startYear) + index}>
      {Number(startYear) + Number(index) + 543}
    </option>
  ))

const mapDayInMonths = (month: string, year: string) => moment(`${year}-${month}`).daysInMonth()

const FormDateSelectGroup: React.FC<FormDateSelectGroupProps> = ({
  error,
  formField,
  formId,
  value,
  onChange,
}) => {
  const [dayValue, setDayValue] = useState(value ? moment(value).format('DD') : '')
  const [monthValue, setMonthValue] = useState(value ? moment(value).format('MM') : '')
  const [yearValue, setYearValue] = useState(value ? moment(value).format('YYYY') : '')

  const minDateMoment = useMemo(
    () =>
      formField.properties?.minDate
        ? moment(formField.properties?.minDate)
        : moment().subtract(100, 'year'),
    [formField.properties?.minDate],
  )

  const maxDateMoment = useMemo(
    () => (formField.properties?.maxDate ? moment(formField.properties?.maxDate) : moment()),
    [formField.properties?.maxDate],
  )

  const currentNumberOfMonths = useMemo(
    () => (yearValue === maxDateMoment.format('YYYY') ? Number(maxDateMoment.format('M')) : 12),
    [maxDateMoment, yearValue],
  )

  const currentMinMonth = useMemo(
    () => (yearValue === minDateMoment.format('YYYY') ? Number(minDateMoment.format('M')) : 1),
    [minDateMoment, yearValue],
  )

  const currentDayInMonths = useMemo(
    () => (monthValue && yearValue ? mapDayInMonths(monthValue, yearValue) : 31),
    [monthValue, yearValue],
  )

  const currentMinDay = useMemo(
    () =>
      yearValue === minDateMoment.format('YYYY') && monthValue === minDateMoment.format('MM')
        ? Number(minDateMoment.format('D'))
        : 1,
    [minDateMoment, monthValue, yearValue],
  )

  const handleDaySelect: ChangeEventHandler<HTMLSelectElement> = e => setDayValue(e.target.value)

  const handleMonthSelect: ChangeEventHandler<HTMLSelectElement> = e =>
    setMonthValue(e.target.value)

  const handleYearSelect: ChangeEventHandler<HTMLSelectElement> = e => setYearValue(e.target.value)

  useEffect(() => {
    if (dayValue && yearValue && monthValue) {
      const newMonthValue = Number(monthValue) > currentNumberOfMonths ? '01' : monthValue
      const newDayValue = Number(dayValue) > currentDayInMonths ? '01' : dayValue
      const isoDateThailand = moment
        .tz(`${yearValue}-${newMonthValue}-${newDayValue}`, 'Asia/Bangkok')
        .format()
      onChange(isoDateThailand)
    }
  }, [currentDayInMonths, currentNumberOfMonths, dayValue, monthValue, onChange, yearValue])

  return (
    <FlexGap id={formId}>
      <FormSelect
        name={`select-day-${formId}`}
        id={`select-day-${formId}`}
        value={dayValue || selectDefaultValue}
        hasValue={!!dayValue}
        error={error}
        onChange={handleDaySelect}
      >
        <option disabled hidden value={selectDefaultValue}>
          วว
        </option>
        {renderDayOptions(currentDayInMonths, currentMinDay)}
      </FormSelect>
      <FormSelect
        name={`select-month-${formId}`}
        id={`select-month-${formId}`}
        value={monthValue || selectDefaultValue}
        hasValue={!!monthValue}
        error={error}
        onChange={handleMonthSelect}
      >
        <option disabled hidden value={selectDefaultValue}>
          ดด
        </option>
        {renderMonthOptions(currentNumberOfMonths, currentMinMonth)}
      </FormSelect>
      <FormSelect
        name={`select-year-${formId}`}
        id={`select-year-${formId}`}
        value={yearValue || selectDefaultValue}
        hasValue={!!yearValue}
        error={error}
        onChange={handleYearSelect}
      >
        <option disabled hidden value={selectDefaultValue}>
          ปปปป
        </option>
        {renderYearOptions(minDateMoment.format('YYYY'), maxDateMoment.format('YYYY'))}
      </FormSelect>
    </FlexGap>
  )
}

export default FormDateSelectGroup
