import { DiscountErrorMessageValue } from '../../components/FormDiscountCode/FormDiscountCode.model'

export const GetPriceQuery = `query GetPriceQuery(
  $collection: String!,
  $customCircle: String!,
  $startTimeUse: String!,
  $discountCode: String
) {
  getPrice(
    input: {
      collection: $collection,
      custom_circle: $customCircle,
      discount_code: $discountCode,
      start_time_use: $startTimeUse
    }
  ) {
    total
    order_id
    payment_id
    items {
      currency
      item_name
      price
      type
    }
  }
}
`

export type PriceCurrencyType = 'THB' | 'PERCENT' | string

export interface GetPriceQueryResult {
  data: {
    getPrice: {
      total: number
      order_id: string
      payment_id: string
      items: {
        currency: PriceCurrencyType
        item_name: string
        price: number
        type: string
      }[]
    }
  } | null
  errors?: {
    path: string[]
    data: unknown | null
    errorType: string
    errorInfo: unknown | null
    locations: {
      line: number
      column: number
      sourceName: unknown | null
    }[]
    message: DiscountErrorMessageValue
  }[]
}
