import { SubmitErrorHandler } from 'react-hook-form'
import { AppText } from '../../App.styled'
import { DependsOnValue, FormDefinition, FormField, FormValue } from './SchemaForm.model'
import { FieldDescription } from './SchemaForm.styled'

export class SchemaFormUtils {
  static defaultRequiredErrorText = 'กรุณากรอกข้อมูลให้ครบถ้วน'

  static scrollToId = (id: string) =>
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  static mapFormId = (formField: Pick<FormField, 'name'>) => `form-${formField.name}`

  static onInvalid: SubmitErrorHandler<FormValue> = error => {
    this.scrollToId(`form-${error[Object.keys(error)[0]].ref?.name}`)
  }

  static findRadioFieldByNameValue = (
    formDefinition: FormDefinition,
    fieldName: string,
    value: string,
  ) =>
    formDefinition.fields.find(
      field =>
        field.type === 'radio' &&
        field.name === fieldName &&
        field.properties?.controlOptions?.findIndex(option => option.value === value) !== -1,
    )

  static isMatchDependsOn = (dependsOn: DependsOnValue, formValue: FormValue) =>
    Object.keys(dependsOn).every(
      dependsOnKey =>
        formValue[dependsOnKey] && dependsOn[dependsOnKey].includes(formValue[dependsOnKey] || ''),
    )

  static errorRadioDependsOn = (formDefinition: FormDefinition, formValue: FormValue): string[] =>
    Object.keys(formValue).reduce<string[]>((acc, fieldName) => {
      const fieldValue = formValue[fieldName]

      if (!fieldValue) {
        return [...acc]
      }

      const field = this.findRadioFieldByNameValue(formDefinition, fieldName, fieldValue)

      if (!field || !field.dependsOn || this.isMatchDependsOn(field.dependsOn, formValue)) {
        return [...acc]
      }

      return [...acc, fieldName]
    }, [])

  static isTextarea = (formField: FormField) =>
    formField.properties &&
    formField.properties.numberOfLines &&
    formField.properties.numberOfLines > 0 &&
    formField.type === 'textInput'

  static mapInputMode = (formField: FormField) => {
    if (formField.type === 'email') {
      return 'email'
    }

    if (formField.type === 'tel') {
      return 'tel'
    }

    return undefined
  }

  static mapValidatePattern = (formField: FormField) => {
    const customErrorMessage = formField.properties?.errorMessage

    if (formField.type === 'email') {
      return {
        value: /^\S+@\S+\.\S+$/,
        message: customErrorMessage || 'กรุณากรอกอีเมล์ให้ถูกต้อง',
      }
    }

    if (formField.type === 'tel') {
      return {
        value: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/,
        message:
          customErrorMessage ||
          `กรุณากรอก${formField.label || formField.title || 'เบอร์โทรศัพท์'}ให้ถูกต้อง`,
      }
    }

    if (formField.properties?.thaiInputOnly) {
      return {
        value:
          // eslint-disable-next-line no-misleading-character-class
          /^[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ]+$/,
        message: customErrorMessage || `กรุณากรอก${formField.label || formField.title}เป็นภาษาไทย`,
      }
    }

    if (formField.properties?.englishInputOnly) {
      return {
        value: /^[A-Za-z][A-Za-z ]*$/,
        message:
          customErrorMessage || `กรุณากรอก${formField.label || formField.title}เป็นภาษาอังกฤษ`,
      }
    }

    return undefined
  }

  static mapPageCardIndexOption = (
    formDefinition: FormDefinition,
    pageIndex: number,
    cardIndex: number,
  ) =>
    formDefinition.pageCardIndexOption &&
    formDefinition.pageCardIndexOption[pageIndex] &&
    formDefinition.pageCardIndexOption[pageIndex][cardIndex]

  static isHideCardBackground = (
    formDefinition: FormDefinition,
    pageIndex: number,
    cardIndex: number,
  ) => this.mapPageCardIndexOption(formDefinition, pageIndex, cardIndex)?.noBackground

  static mapSubmitDefaultEmptyDataValue = (
    inputFormDefinition: FormDefinition,
    formValue: FormValue,
  ) => {
    const defaultValueIfEmptyFields = inputFormDefinition.fields.filter(
      field => field.properties?.defaultValueIfEmpty,
    )

    if (defaultValueIfEmptyFields.length === 0) {
      return formValue
    }

    return Object.keys(formValue).reduce((acc, fieldName) => {
      const findField: FormField | undefined = defaultValueIfEmptyFields.find(
        field => field.name === fieldName && field.properties?.defaultValueIfEmpty,
      )

      return {
        ...acc,
        [fieldName]: !findField
          ? formValue[fieldName]
          : formValue[fieldName] || findField.properties?.defaultValueIfEmpty,
      }
    }, {})
  }

  static mapSubmitImageDataValue = (inputFormDefinition: FormDefinition, formValue: FormValue) => {
    const imageFields = inputFormDefinition.fields.filter(field => field.type === 'image')

    if (imageFields.length === 0) {
      return formValue
    }

    return Object.keys(formValue).reduce((acc, fieldName) => {
      const findField: FormField | undefined = imageFields.find(field => field.name === fieldName)

      return {
        ...acc,
        [fieldName]: !findField ? formValue[fieldName] : formValue[fieldName]?.split(',')[1],
      }
    }, {})
  }

  static mapMaxCardIndex = (formFields: Pick<FormField, 'cardIndex'>[]) =>
    formFields.reduce((acc, cur) => (cur.cardIndex > acc ? cur.cardIndex : acc), 0)

  static renderTextMaxLength = (formField: FormField, fieldValue: string) =>
    formField.properties?.textMaxLength && (
      <AppText fontSize=".875rem" style={{ marginLeft: 'auto' }}>
        {`${fieldValue.length}/${formField.properties?.textMaxLength}`}
      </AppText>
    )

  static renderCardTitle = (formDefinition: FormDefinition, pageIndex: number, cardIndex: number) =>
    this.mapPageCardIndexOption(formDefinition, pageIndex, cardIndex) && (
      <AppText fontWeight="bold">
        {this.mapPageCardIndexOption(formDefinition, pageIndex, cardIndex)?.cardTitle}
      </AppText>
    )

  static renderFieldTitle = (formField: FormField) =>
    formField.title && (
      <AppText
        fontWeight={formField.titleFontWeight || '700'}
        required={!formField.hideTitleRequired && formField.required}
      >
        {formField.title}
      </AppText>
    )

  static renderFieldRemark = (formField: FormField) =>
    formField.remark && (
      <AppText fontSize=".875rem" color="#667085">
        {formField.remark}
      </AppText>
    )

  static renderFieldSubtitle = (formField: FormField) =>
    formField.subtitle && (
      <AppText required={formField.showSubtitleRequired}>{formField.subtitle}</AppText>
    )

  static renderFieldDescription = (formField: FormField) =>
    formField.description && (
      <FieldDescription
        style={{ color: formField.descriptionColor, fontSize: formField.descriptionFontSize }}
      >
        {formField.description}
      </FieldDescription>
    )

  static renderFieldFooter = (formField: FormField) =>
    formField.footerText && (
      <AppText fontSize=".875rem" color="#667085">
        {formField.footerText}
      </AppText>
    )

  static renderFieldLabel = (formField: FormField) =>
    formField.label &&
    !formField.hideFormLabel && (
      <AppText required={formField.required} fontSize=".875rem" fontWeight="bold">
        {formField.label}
      </AppText>
    )
}
