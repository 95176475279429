import { Routes, Route, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import createTheme from '@mui/material/styles/createTheme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import SchemaForm from './components/SchemaForm/SchemaForm.component'
import { formDefinition as devasTarotFormDefinition } from './FormDefinition/DevasTarot'
import { formDefinition as purchaseDevasTarotFormDefinition } from './FormDefinition/PurchaseDevasTarot'
import { formDefinition as murathiDevasTarotFormDefinition } from './FormDefinition/MurathiDevasTarot'
import { formDefinition as purchaseMurathiDevasTarotFormDefinition } from './FormDefinition/PurchaseMurathiDevasTarot'
import { formDefinition as personalCardFormDefinition } from './FormDefinition/PersonalCard'
import { formDefinition as purchasePersonalCardFormDefinition } from './FormDefinition/PurchasePersonalCard'
import { formDefinition as personalGodCardFormDefinition } from './FormDefinition/PersonalGodCard'
import { formDefinition as purchasePersonalGodCardFormDefinition } from './FormDefinition/PurchasePersonalGodCard'
import { formDefinition as firstCollectionFormDefinition } from './FormDefinition/FirstCollection'
import { formDefinition as purchaseFirstCollectionFormDefinition } from './FormDefinition/PurchaseFirstCollection'
import { formDefinition as starTarotFormDefinition } from './FormDefinition/StarTarot'
import { formDefinition as purchaseStarTarotFormDefinition } from './FormDefinition/PurchaseStarTarot'
import { formDefinition as lunaLapinFormDefinition } from './FormDefinition/LunaLapin'
import { formDefinition as arcanaTarotMobileFormDefinition } from './FormDefinition/ArcanaTarotMobile'
import { formDefinition as purchaseArcanaTarotMobileFormDefinition } from './FormDefinition/PurchaseArcanaTarotMobile'
import { formDefinition as arcanaTarotDesktopFormDefinition } from './FormDefinition/ArcanaTarotDesktop'
import { formDefinition as purchaseArcanaTarotDesktopFormDefinition } from './FormDefinition/PurchaseArcanaTarotDesktop'
import { formDefinition as asuraRahuFormDefinition } from './FormDefinition/AsuraRahu'
import { formDefinition as purchaseAsuraRahuFormDefinition } from './FormDefinition/PurchaseAsuraRahu'
import { formDefinition as chineseGodFormDefinition } from './FormDefinition/ChineseGod'
import { formDefinition as purchaseChineseGodFormDefinition } from './FormDefinition/PurchaseChineseGod'
import { formDefinition as chineseGodV2FormDefinition } from './FormDefinition/ChineseGodV2'
import { formDefinition as purchaseChineseGodV2FormDefinition } from './FormDefinition/PurchaseChineseGodV2'
import { formDefinition as furtunateCatLuckFormDefinition } from './FormDefinition/FurtunateCatLuck'
import { formDefinition as purchaseFurtunateCatLuckFormDefinition } from './FormDefinition/PurchaseFurtunateCatLuck'
import { formDefinition as divineGodsFormDefinition } from './FormDefinition/DivineGods'
import { formDefinition as purchaseDivineGodsFormDefinition } from './FormDefinition/PurchaseDivineGods'
import { formDefinition as energeticEmbracingFormDefinition } from './FormDefinition/EnergeticEmbracing'
import { formDefinition as purchaseEnergeticEmbracingFormDefinition } from './FormDefinition/PurchaseEnergeticEmbracing'

// Redeem
import { formDefinition as dtacRedeemFormDefinition } from './FormDefinition/DtacRedeem'
import { formDefinition as dtacRabbitRedeemFormDefinition } from './FormDefinition/DtacRabbitRedeem'
import { formDefinition as the1RedeemFormDefinition } from './FormDefinition/The1Redeem'

// Moo For You
import MooNagaPage from './pages/MooForYouPage/MooNaga.page'

import appSyncConfig from './config/aws-exports'
import { FormDefinition, FormValue } from './components/SchemaForm/SchemaForm.model'
import {
  CreateOrderMutation,
  CreateOrderMutationResult,
} from './graphql/mutations/createOrderMutation'
import GradientLayout from './layouts/GradientLayout.component'
import SuccessOrderPage from './pages/SuccessOrder.page'
import FailureModal from './components/FailureModal/FaiilureModal.component'
import { GetPriceQuery, GetPriceQueryResult } from './graphql/queries/getPrice.query'
import RedeemCodeFormPage from './pages/RedeemCodeFormPage/RedeemCodeFormPage.component'
import RedeemCodePage from './pages/RedeemCodePage/RedeemCodePage.component'
import ViewOrderPage from './pages/ViewOrder.page'
import SearchOrderPage from './pages/SearchOrder.page'
import {
  DiscountErrorMessage,
  DiscountErrorMessageValue,
} from './components/FormDiscountCode/FormDiscountCode.model'
import { VerifyDiscountQuery } from './graphql/queries/verifyDiscount.query'

const muiTheme = createTheme({
  typography: {
    fontFamily: "'LINESeedSans', sans-serif",
  },
})

Amplify.configure(appSyncConfig)

const mapFormValue = (formValue: FormValue, discountCode = '') => {
  const newFormValue = discountCode ? { ...formValue, discountCode } : formValue

  if (
    newFormValue.collection === 'luna_lapin' ||
    newFormValue.collection === 'dtac' ||
    newFormValue.collection === 'dtac_rabbit' ||
    newFormValue.collection === 'the1'
  ) {
    return { ...newFormValue, template: newFormValue.choice }
  }

  return newFormValue
}

const mapDiscountCodeErrorMessage = (result: GetPriceQueryResult) =>
  result.errors &&
  result.errors.length > 0 &&
  Object.keys(DiscountErrorMessage).includes(result.errors[0].message)
    ? result.errors[0].message
    : ''

const App = () => {
  const [priceQueryData, setPriceQueryData] = useState<GetPriceQueryResult>()
  const [originalPriceQueryData, setOriginalPriceQueryData] = useState<GetPriceQueryResult>()
  const [discountCode, setDiscountCode] = useState('')
  const [orderId, setOrderId] = useState<string | undefined>('')
  const [customerId, setCustomerId] = useState<string | undefined>('')
  const [paymentId, setPaymentId] = useState<string | undefined>('')
  const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = useState<
    DiscountErrorMessageValue | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false)
  const navigate = useNavigate()

  const handleCreateOrder = async (formValue: FormValue, noNavigate = false) => {
    try {
      setIsLoading(true)
      if (formValue.collection === 'arcana_mobile' || formValue.collection === 'arcana_desktop') {
        formValue.template = formValue.choice
          ? 'arcana_'.concat(formValue.choice)
          : formValue.choice
      }

      const result = (await API.graphql(
        graphqlOperation(CreateOrderMutation, mapFormValue(formValue, discountCode)),
      )) as CreateOrderMutationResult

      if (!noNavigate) {
        return navigate(
          `/success-order/${result.data.createOrder.customer_id}/${result.data.createOrder.order_id}`,
        )
      }

      return result.data.createOrder
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setIsFailureModalOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitForm = async (formValue: FormValue) => handleCreateOrder(formValue)

  const handleSubmitRedeemForm = async (formValue: FormValue) => {
    try {
      setIsLoading(true)

      const result = (await API.graphql(
        graphqlOperation(CreateOrderMutation, mapFormValue(formValue)),
      )) as CreateOrderMutationResult

      navigate(
        `/redeem-success-order/${result.data.createOrder.customer_id}/${result.data.createOrder.order_id}`,
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      setIsFailureModalOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGetPriceQueryData = async (formValue: FormValue, formDefinition?: FormDefinition) => {
    try {
      setIsLoading(true)

      if (formValue.discountCode) {
        await API.graphql(
          graphqlOperation(VerifyDiscountQuery, {
            collection: formValue.collection,
            discountCode: formValue.discountCode,
          }),
        )
      }

      const result = (await API.graphql(
        graphqlOperation(GetPriceQuery, {
          collection: formValue.collection,
          customCircle: formValue.customCircle,
          discountCode: formValue.discountCode,
          startTimeUse: formValue.startTimeUse,
        }),
      )) as GetPriceQueryResult

      setDiscountCode(formValue.discountCode || '')
      setDiscountCodeErrorMessage(undefined)
      setPriceQueryData({ ...result })

      if (!priceQueryData && !discountCode) {
        setOriginalPriceQueryData({ ...result })

        if (formDefinition && formDefinition.paymentMethod === 'PAY_SOLUTION') {
          const createOrderResult = await handleCreateOrder(
            {
              ...formValue,
              saleUsername: formValue.email,
              orderId: result.data?.getPrice.order_id,
              paymentId: result.data?.getPrice.payment_id,
            },
            true,
          )

          setOrderId(createOrderResult?.order_id)
          setCustomerId(createOrderResult?.customer_id)
          setPaymentId(createOrderResult?.payment_id)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)

      const priceQueryResult = error as GetPriceQueryResult
      const errorMessageResult = mapDiscountCodeErrorMessage(priceQueryResult)

      setDiscountCode('')
      setDiscountCodeErrorMessage(undefined)

      if (!errorMessageResult) {
        setIsFailureModalOpen(true)
        return
      }

      if (originalPriceQueryData) {
        setPriceQueryData({ ...originalPriceQueryData })
      }

      setDiscountCodeErrorMessage(errorMessageResult)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeDiscountCode = (value: string) => setDiscountCode(value)

  const handleChangeLoading = useCallback((value: boolean) => setIsLoading(value), [])

  const onCloseFailureModal = () => setIsFailureModalOpen(false)

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [isLoading])

  return (
    <ThemeProvider theme={muiTheme}>
      <Routes>
        <Route element={<GradientLayout isLoading={isLoading} />}>
          <Route
            path="/devas-tarot"
            element={
              <SchemaForm
                formDefinition={devasTarotFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/devas-tarot"
            element={
              <SchemaForm
                formDefinition={purchaseDevasTarotFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/murathi-devas-tarot"
            element={
              <SchemaForm
                formDefinition={murathiDevasTarotFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/murathi-devas-tarot"
            element={
              <SchemaForm
                formDefinition={purchaseMurathiDevasTarotFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/personal-card"
            element={
              <SchemaForm
                formDefinition={personalCardFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/personal-card"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchasePersonalCardFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/personal-god-card"
            element={
              <SchemaForm
                formDefinition={personalGodCardFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/personal-god-card"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchasePersonalGodCardFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/1st-collection"
            element={
              <SchemaForm
                formDefinition={firstCollectionFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/1st-collection"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseFirstCollectionFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/star-tarot"
            element={
              <SchemaForm
                formDefinition={starTarotFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/star-tarot"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseStarTarotFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/luna-lapin"
            element={
              <SchemaForm
                formDefinition={lunaLapinFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/arcana-tarot-mobile"
            element={
              <SchemaForm
                formDefinition={arcanaTarotMobileFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/arcana-tarot-mobile"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseArcanaTarotMobileFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/arcana-tarot-desktop"
            element={
              <SchemaForm
                formDefinition={arcanaTarotDesktopFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/arcana-tarot-desktop"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseArcanaTarotDesktopFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/asura-rahu"
            element={
              <SchemaForm
                formDefinition={asuraRahuFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/asura-rahu"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseAsuraRahuFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/chinese-god"
            element={
              <SchemaForm
                formDefinition={chineseGodFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/chinese-god"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseChineseGodFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/chinese-god-v2"
            element={
              <SchemaForm
                formDefinition={chineseGodV2FormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/chinese-god-v2"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseChineseGodV2FormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/furtunate-catluck"
            element={
              <SchemaForm
                formDefinition={furtunateCatLuckFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/furtunate-catluck"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseFurtunateCatLuckFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/divine-gods"
            element={
              <SchemaForm
                formDefinition={divineGodsFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/divine-gods"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseDivineGodsFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/energetic-embracing"
            element={
              <SchemaForm
                formDefinition={energeticEmbracingFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />
          <Route
            path="/purchase/energetic-embracing"
            element={
              <SchemaForm
                customerId={customerId}
                orderId={orderId}
                paymentId={paymentId}
                formDefinition={purchaseEnergeticEmbracingFormDefinition}
                discountCode={discountCode}
                discountCodeErrorMessage={discountCodeErrorMessage}
                priceQueryData={priceQueryData}
                onChangeDiscountCode={handleChangeDiscountCode}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={onSubmitForm}
              />
            }
          />

          {/* Redeem */}
          <Route
            path="/dtac-redeem/:redeemCode"
            element={
              <RedeemCodeFormPage
                failNavigateUrl="/dtac-redeem"
                formDefinition={dtacRedeemFormDefinition}
                priceQueryData={priceQueryData}
                onChangeLoading={handleChangeLoading}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={handleSubmitRedeemForm}
              />
            }
          />
          <Route
            path="/dtac-rabbit-redeem/:redeemCode"
            element={
              <RedeemCodeFormPage
                failNavigateUrl="/dtac-rabbit-redeem"
                formDefinition={dtacRabbitRedeemFormDefinition}
                priceQueryData={priceQueryData}
                onChangeLoading={handleChangeLoading}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={handleSubmitRedeemForm}
              />
            }
          />
          <Route
            path="/the1-redeem/:redeemCode"
            element={
              <RedeemCodeFormPage
                failNavigateUrl="/the1-redeem"
                formDefinition={the1RedeemFormDefinition}
                priceQueryData={priceQueryData}
                onChangeLoading={handleChangeLoading}
                onGetPrice={handleGetPriceQueryData}
                onSubmit={handleSubmitRedeemForm}
              />
            }
          />
        </Route>
        <Route element={<GradientLayout isLoading={isLoading} hideLogo />}>
          <Route
            path="/the1-redeem"
            element={
              <RedeemCodePage
                title="The 1 Today x มูเตเวิร์ล"
                successRootPath="/the1-redeem"
                onChangeLoading={handleChangeLoading}
              />
            }
          />
          <Route
            path="/dtac-redeem"
            element={
              <RedeemCodePage
                title="'คนดวงดี' จากดีแทค x มูเตเวิร์ล"
                successRootPath="/dtac-redeem"
                onChangeLoading={handleChangeLoading}
              />
            }
          />
          <Route
            path="/dtac-rabbit-redeem"
            element={
              <RedeemCodePage
                title="'คนดวงดี' จากดีแทค x มูเตเวิร์ล"
                successRootPath="/dtac-rabbit-redeem"
                onChangeLoading={handleChangeLoading}
              />
            }
          />
        </Route>
        <Route
          path="/redeem-success-order/:customerId/:orderId"
          element={
            <SuccessOrderPage
              title="ลงทะเบียนรับวอลเปเปอร์สำเร็จ"
              content="ตรวจสอบสถานะและรายละเอียดการลงทะเบียน รับวอลเปเปอร์ได้ที่อีเมลของท่าน"
              contentMaxWidth="calc(100vw - 4em)"
              buttonText="ตรวจสอบสถานะการลงทะเบียน"
            />
          }
        />
        <Route path="/success-order/:customerId/:orderId" element={<SuccessOrderPage />} />
        <Route path="/success-order" element={<SuccessOrderPage navigateUrl="/search-order" />} />
        <Route path="/view-order/:customerId/:orderId" element={<ViewOrderPage />} />
        <Route path="/search-order" element={<SearchOrderPage />} />

        {/* Moo For You */}
        <Route path="/mooforyou/moo-naga" element={<MooNagaPage />} />
      </Routes>
      <FailureModal open={isFailureModalOpen} onClose={onCloseFailureModal} />
    </ThemeProvider>
  )
}
export default App
