import { DiscountErrorMessageValue } from '../../components/FormDiscountCode/FormDiscountCode.model'

export const VerifyDiscountQuery = `query MyQuery (
  $collection: String!,
  $discountCode: String!
) {
  verifyDiscount(input: {collection: $collection, discount_code: $discountCode})
}
`

export interface VerifyQueryResult {
  data: {
    verifyDiscount: boolean | null
  }
  errors?: {
    path: string[]
    data: unknown | null
    errorType: string
    errorInfo: unknown | null
    locations: {
      line: number
      column: number
      sourceName: unknown | null
    }[]
    message: DiscountErrorMessageValue
  }[]
}
