import { useCallback, useMemo } from 'react'
import { AppPrimaryButton, AppText, FlexGap } from '../../App.styled'
import {
  FormControlWrapper,
  FormErrorMessage,
  FormTextInput,
} from '../SchemaForm/SchemaForm.styled'
import { DiscountErrorMessage, FormDiscountCodeParams } from './FormDiscountCode.model'

const FormDiscountCode: React.FC<FormDiscountCodeParams> = ({
  discountCode,
  errorMessage,
  onChangeDiscountCode,
  onSubmit,
}) => {
  const renderErrorMessage = useMemo(
    () =>
      !!errorMessage && (
        <FormControlWrapper>
          <FormErrorMessage>{DiscountErrorMessage[errorMessage]}</FormErrorMessage>
        </FormControlWrapper>
      ),
    [errorMessage],
  )

  const handleDiscountCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      onChangeDiscountCode ? onChangeDiscountCode(e.target.value) : undefined,
    [onChangeDiscountCode],
  )

  const handleSubmit = useCallback(
    () => (discountCode ? onSubmit(discountCode) : undefined),
    [discountCode, onSubmit],
  )

  return (
    <FlexGap flexDirection="column">
      <AppText fontWeight="700">โค้ดส่วนลด</AppText>
      <FormControlWrapper>
        <FlexGap>
          <FormTextInput
            error={!!errorMessage}
            id="discount-code"
            type="text"
            placeholder="กรุณากรอกโค้ดส่วนลด"
            onChange={handleDiscountCodeChange}
            value={discountCode}
          />
          <AppPrimaryButton variant="contained" sx={{ minWidth: '6.25rem' }} onClick={handleSubmit}>
            ใช้โค้ด
          </AppPrimaryButton>
        </FlexGap>
        {renderErrorMessage}
      </FormControlWrapper>
    </FlexGap>
  )
}

export default FormDiscountCode
