import Button from '@mui/material/Button/Button'
import React, { useCallback, useMemo } from 'react'
import { AppFormCard, AppPrimaryText, AppText, FlexGap } from '../../App.styled'
import { FormField, SummaryField } from '../SchemaForm/SchemaForm.model'
import { FormReadonlyInput } from '../SchemaForm/SchemaForm.styled'
import { SchemaFormUtils } from '../SchemaForm/SchemaForm.utils'
import { DateUtils } from '../../utils/date.utils'
import { SummarySchemaFormUtils } from './SummarySchemaForm.utils'
import { PriceCurrencyType } from '../../graphql/queries/getPrice.query'
import { NumberUtils } from '../../utils/number.utils'
import FormDiscountCode from '../FormDiscountCode/FormDiscountCode.component'
import { SumarySchemaFormProps } from './SummarySchemaForm.model'

const SummarySchemaForm: React.FC<SumarySchemaFormProps> = ({
  discountCode,
  discountCodeErrorMessage,
  formDefinition,
  formValue,
  priceQueryData,
  onChangeDiscountCode,
  onChangePage,
  onGetPrice,
}) => {
  const handleDiscountCodeGetPrice = useCallback(
    (newDiscountCode: string) =>
      onGetPrice({
        collection: formValue.collection,
        discountCode: newDiscountCode,
        startTimeUse: formValue.startTimeUse,
        customCircle: formValue.customCircle,
      }),
    [formValue.collection, formValue.customCircle, formValue.startTimeUse, onGetPrice],
  )

  const mapFieldLabelText = useCallback(
    (summaryField: SummaryField) => {
      if (summaryField.label || !summaryField.name) {
        return summaryField.label
      }

      const findField: FormField | undefined = SummarySchemaFormUtils.findFormFieldByName(
        formDefinition.fields,
        summaryField.name,
      )

      return findField?.title || findField?.label
    },
    [formDefinition.fields],
  )

  const mapFieldValueText = useCallback(
    (summaryFieldName: string, summaryField: SummaryField) => {
      const resultFormValue = formValue[summaryFieldName]
      const findField: FormField | undefined = formDefinition.fields.find(field =>
        !field.dependsOn
          ? field.name === summaryFieldName
          : SchemaFormUtils.isMatchDependsOn(field.dependsOn, formValue) &&
            field.name === summaryFieldName,
      )

      if (!findField) {
        return summaryField.text
      }

      if (['radio', 'select'].includes(findField?.type)) {
        const findControlOption = findField.properties?.controlOptions?.find(
          option => option.value === formValue[summaryFieldName],
        )

        return findControlOption ? findControlOption.label || findControlOption.value : undefined
      }

      if (summaryField.textObject && resultFormValue) {
        return summaryField.textObject[resultFormValue]
      }

      return summaryField.text || resultFormValue
    },
    [formDefinition.fields, formValue],
  )

  const renderFieldValueText = useCallback(
    (summaryField: SummaryField) => {
      const summaryFieldName = summaryField.name

      if (summaryField.text || (!summaryFieldName && !summaryField.names)) {
        return summaryField.text
      }

      if (summaryField.names) {
        return summaryField.names
          .map(fieldName => mapFieldValueText(fieldName, summaryField))
          .join(' ')
      }

      const valueText = summaryFieldName
        ? mapFieldValueText(summaryFieldName, summaryField)
        : undefined

      if (!valueText) {
        return
      }

      if (summaryField.type === 'readonlyInput') {
        return summaryField.properties?.readonlyInputValue || valueText
      }

      if (summaryField.type === 'date') {
        return DateUtils.beYearFormat(valueText)
      }

      return valueText
    },
    [mapFieldValueText],
  )

  const renderSummaryField = useCallback(
    (summaryField: SummaryField) => {
      const key = `summary-field-${summaryField.name || ''}-${
        summaryField.names?.join(',') || ''
      }-${summaryField.template || ''}`

      if (summaryField.template === 'saleChannel') {
        const findLabelImageSrc = SummarySchemaFormUtils.findFormFieldByName(
          formDefinition.fields,
          'saleChannel',
        )?.properties?.controlOptions?.find(
          option => option.value === formValue?.saleChannel,
        )?.labelImageSrc

        return (
          <FlexGap key={key} flexDirection="column">
            <AppText fontWeight={summaryField.labelFontWeight || '700'}>
              {summaryField.properties?.saleChannelTemplateTitle || 'ช่องทางการสั่งซื้อ'}
            </AppText>
            <FlexGap>
              <img src={findLabelImageSrc} alt={`saleChannel-${formValue.saleChannel}`} />
              <span>{formValue.saleUsername}</span>
            </FlexGap>
          </FlexGap>
        )
      }

      if (summaryField.template === 'discountCode') {
        return (
          <FormDiscountCode
            key={key}
            discountCode={discountCode}
            errorMessage={discountCodeErrorMessage}
            onChangeDiscountCode={onChangeDiscountCode}
            onSubmit={handleDiscountCodeGetPrice}
          />
        )
      }

      if (summaryField.type === 'divider') {
        return (
          <div key={key} style={{ width: '100%', height: '1px', backgroundColor: '#D0D5DD' }} />
        )
      }

      const fieldValueText =
        summaryField.type === 'readonlyInput'
          ? summaryField.properties?.readonlyInputValue || renderFieldValueText(summaryField)
          : renderFieldValueText(summaryField)

      if (!fieldValueText) {
        return
      }

      const fieldLabelText = mapFieldLabelText(summaryField)

      if (summaryField.type === 'readonlyInput') {
        return (
          <FlexGap key={key} flexDirection="column" gap=".5em">
            {fieldLabelText && (
              <AppText fontWeight={summaryField.labelFontWeight}>{fieldLabelText}</AppText>
            )}
            <FormReadonlyInput>
              {summaryField.properties?.readonlyInputValue || fieldValueText}
            </FormReadonlyInput>
          </FlexGap>
        )
      }

      if (summaryField.type === 'image') {
        return (
          <FlexGap key={key} flexDirection="column">
            {fieldLabelText && (
              <AppText
                fontWeight="700"
                maxWidth={summaryField.labelFullWidth ? '' : 'calc(100vw - 10.9375em)'}
              >
                {fieldLabelText}
              </AppText>
            )}
            <img
              src={fieldValueText}
              alt={summaryField.name}
              width="160"
              style={{ margin: 'auto' }}
            />
          </FlexGap>
        )
      }

      return (
        <FlexGap key={key} justifyContent="space-between">
          {fieldLabelText && (
            <AppText
              color="#667085"
              maxWidth={summaryField.labelFullWidth ? '' : 'calc(100vw - 10.9375em)'}
            >
              {fieldLabelText}
            </AppText>
          )}
          <AppText
            maxWidth={summaryField.textFullWidth ? '' : 'calc(100vw - 12em)'}
            variant={summaryField.textVariant}
            style={{ minWidth: summaryField.textMinWidth, textAlign: 'right' }}
          >
            {fieldValueText}
          </AppText>
        </FlexGap>
      )
    },
    [
      discountCode,
      discountCodeErrorMessage,
      formDefinition.fields,
      formValue.saleChannel,
      formValue.saleUsername,
      handleDiscountCodeGetPrice,
      mapFieldLabelText,
      onChangeDiscountCode,
      renderFieldValueText,
    ],
  )

  const mapFilterCardIndex = useCallback(
    (summaryFields: SummaryField[], cardIndex: number) =>
      summaryFields
        .filter(summaryField => summaryField.cardIndex === cardIndex)
        .map(renderSummaryField),
    [renderSummaryField],
  )

  const maxCardIndex = SchemaFormUtils.mapMaxCardIndex(formDefinition.summaryFields)

  const renderBackPageButton = useCallback(
    (cardIndex: number) => {
      const option =
        formDefinition.summaryCardIndexOption &&
        formDefinition.summaryCardIndexOption[cardIndex]?.backPageOption

      if (!option) {
        return
      }

      return (
        <Button
          sx={{
            color: '#667085',
            fontSize: '.75em',
            fontWeight: 'normal',
            display: 'flex',
            gap: '.25em',
            alignItems: 'center',
          }}
          variant="text"
          onClick={() => onChangePage(option.toPageIndex)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.33398 2.66665H2.66732C2.3137 2.66665 1.97456 2.80713 1.72451 3.05718C1.47446 3.30723 1.33398 3.64637 1.33398 3.99999V13.3333C1.33398 13.6869 1.47446 14.0261 1.72451 14.2761C1.97456 14.5262 2.3137 14.6667 2.66732 14.6667H12.0007C12.3543 14.6667 12.6934 14.5262 12.9435 14.2761C13.1935 14.0261 13.334 13.6869 13.334 13.3333V8.66666M12.334 1.66665C12.5992 1.40144 12.9589 1.25244 13.334 1.25244C13.7091 1.25244 14.0688 1.40144 14.334 1.66665C14.5992 1.93187 14.7482 2.29158 14.7482 2.66665C14.7482 3.04173 14.5992 3.40144 14.334 3.66665L8.00065 9.99999L5.33398 10.6667L6.00065 7.99999L12.334 1.66665Z"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          {option.label}
        </Button>
      )
    },
    [formDefinition.summaryCardIndexOption, onChangePage],
  )

  const mapCardTitle = useCallback(
    (cardIndex: number) =>
      formDefinition.summaryCardIndexOption &&
      formDefinition.summaryCardIndexOption[cardIndex]?.cardTitle,
    [formDefinition.summaryCardIndexOption],
  )

  const isHideCardBackground = useCallback(
    (cardIndex: number) =>
      formDefinition.summaryCardIndexOption &&
      formDefinition.summaryCardIndexOption[cardIndex]?.noBackground,
    [formDefinition.summaryCardIndexOption],
  )

  const mapCurrencyText = useCallback((currency: PriceCurrencyType, priceText: string) => {
    if (currency === 'THB') {
      return `฿${priceText}`
    }

    if (currency === 'PERCENT') {
      return `${priceText}%`
    }

    return `${priceText} ${currency}`
  }, [])

  const renderHeaderCard = useCallback(
    (cardIndex: number) =>
      (mapCardTitle(cardIndex) || renderBackPageButton(cardIndex)) && (
        <FlexGap justifyContent="space-between" alignItems="center">
          {mapCardTitle(cardIndex) && <AppText fontWeight="700">{mapCardTitle(cardIndex)}</AppText>}

          {renderBackPageButton(cardIndex)}
        </FlexGap>
      ),
    [mapCardTitle, renderBackPageButton],
  )

  const renderPriceTextList = useMemo(
    () =>
      priceQueryData?.data?.getPrice.items.map(item => (
        <FlexGap
          key={`price-card-${item.item_name}-${item.type}-${item.currency}`}
          justifyContent="space-between"
        >
          {item.type === 'discount' ? (
            <>
              <AppText color="#667085" style={{ flex: 2, wordBreak: 'break-word' }}>
                ส่วนลด
              </AppText>
              <AppText color="#F04438">
                -{mapCurrencyText(item.currency, NumberUtils.numberWithComma(item.price))}
              </AppText>
            </>
          ) : (
            <>
              <AppText color="#667085" style={{ flex: 2, wordBreak: 'break-word' }}>
                {item.item_name}
              </AppText>
              <AppText>
                {mapCurrencyText(item.currency, NumberUtils.numberWithComma(item.price))}
              </AppText>
            </>
          )}
        </FlexGap>
      )),
    [mapCurrencyText, priceQueryData?.data?.getPrice.items],
  )

  const renderPriceCard = useMemo(
    () =>
      priceQueryData && (
        <AppFormCard>
          <AppText fontWeight="700">สรุปยอดสั่งซื้อวอลเปเปอร์</AppText>
          {renderPriceTextList}
          <FlexGap justifyContent="space-between">
            <AppText color="#667085">รวมทั้งหมด</AppText>
            <AppText fontWeight="700" style={{ flex: 1, textAlign: 'right' }}>
              {priceQueryData.data &&
                mapCurrencyText(
                  'THB',
                  NumberUtils.numberWithComma(priceQueryData.data?.getPrice.total),
                )}
            </AppText>
          </FlexGap>
        </AppFormCard>
      ),
    [mapCurrencyText, priceQueryData, renderPriceTextList],
  )

  const renderFormCards = useMemo(
    () => (
      <FlexGap flexDirection="column" width="100%" gap="1.5em">
        {Array.from({ length: maxCardIndex + 1 }, (_, cardIndex) =>
          isHideCardBackground(cardIndex) ? (
            mapFilterCardIndex(formDefinition.summaryFields, cardIndex)
          ) : (
            <AppFormCard key={`summary-form-card-${cardIndex}`}>
              {renderHeaderCard(cardIndex)}
              {mapFilterCardIndex(formDefinition.summaryFields, cardIndex)}
            </AppFormCard>
          ),
        )}
        {!formDefinition.hidePriceSummary && renderPriceCard}
        <AppText variant="danger" fontWeight="700">
          *
          {formDefinition.summaryWarningText ||
            'หลังจากสั่งซื้อสินค้าแล้ว ไม่สามารถเปลี่ยนแปลง หรือยกเลิกได้ทุกกรณี'}
        </AppText>
      </FlexGap>
    ),
    [
      formDefinition.hidePriceSummary,
      formDefinition.summaryFields,
      formDefinition.summaryWarningText,
      isHideCardBackground,
      mapFilterCardIndex,
      maxCardIndex,
      renderHeaderCard,
      renderPriceCard,
    ],
  )

  return (
    <FlexGap flexDirection="column" alignItems="center" gap="1.25em">
      <AppPrimaryText fontSize="1.5em" style={{ textAlign: 'center', padding: '0 2.75em' }}>
        {formDefinition.summaryTitle}
      </AppPrimaryText>
      {renderFormCards}
    </FlexGap>
  )
}

export default SummarySchemaForm
