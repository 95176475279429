import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppOutlinePrimary, AppText, FlexGap } from '../App.styled'

const SuccessOrderPage: React.FC<{
  title?: string
  content?: string
  contentMaxWidth?: string
  buttonText?: string
  navigateUrl?: string
}> = ({
  title = 'สั่งซื้อวอลเปเปอร์สำเร็จ',
  content = 'ตรวจสอบสถานะและรายละเอียดคำสั่งซื้อ ได้ที่อีเมลของท่าน',
  buttonText = 'ตรวจสอบสถานะคำสั่งซื้อของคุณ',
  contentMaxWidth = 'calc(100vw - 7em)',
  navigateUrl = '',
}) => {
  const { customerId, orderId } = useParams()
  const navigate = useNavigate()

  return (
    <FlexGap
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: '#931720', color: 'white', height: '100vh' }}
    >
      <FlexGap flexDirection="column" gap="3.75em" alignItems="center">
        <FlexGap flexDirection="column">
          <FlexGap flexDirection="column" gap="2em" alignItems="center">
            <img src="/images/success-order.svg" alt="Success Order" width="150" />
            <AppText fontWeight="700" fontSize="1.5em">
              {title}
            </AppText>
          </FlexGap>
          <AppText style={{ textAlign: 'center' }} maxWidth={contentMaxWidth}>
            {content}
          </AppText>
        </FlexGap>
        <AppOutlinePrimary
          onClick={() => navigate(navigateUrl || `/view-order/${customerId}/${orderId}`)}
          style={{ width: '311px' }}
        >
          {buttonText}
        </AppOutlinePrimary>
      </FlexGap>
    </FlexGap>
  )
}

export default SuccessOrderPage
