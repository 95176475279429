import { API, graphqlOperation } from 'aws-amplify'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppPrimaryButton, AppPrimaryText } from '../../App.styled'
import FailureModal from '../../components/FailureModal/FaiilureModal.component'
import SocialLinkGroup from '../../components/SocialLinkGroup/SocialLinkGroup.component'
import { VerifyDiscountQuery } from '../../graphql/queries/verifyDiscount.query'
import { RedeemCodePageProps } from './RedeemCodePage.model'
import {
  RedeemCodeCardForm,
  RedeemCodeFlexGap,
  RedeemCodeInput,
  RedeemCodeInputWrapper,
  RedeemCodeWrapper,
} from './RedeemCodePage.styled'

const RedeemCodePage: React.FC<RedeemCodePageProps> = ({
  title,
  successRootPath,
  onChangeLoading,
}) => {
  const [redeemCode, setRedeemCode] = useState('')
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false)

  const navigate = useNavigate()

  const handleVerifyCode: React.FormEventHandler<HTMLFormElement> = useCallback(
    async e => {
      e.preventDefault()

      if (!redeemCode) {
        return
      }

      try {
        onChangeLoading(true)
        // @TODO: Seperation collection redeem code config as variable
        await API.graphql(
          graphqlOperation(VerifyDiscountQuery, { collection: 'the1', discountCode: redeemCode }),
        )
        navigate(`${successRootPath}/${redeemCode}`)
      } catch {
        setIsFailureModalOpen(true)
      } finally {
        onChangeLoading(false)
      }
    },
    [navigate, onChangeLoading, redeemCode, successRootPath],
  )

  const handleRedeemCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setRedeemCode(e.target.value),
    [],
  )

  const onCloseFailureModal = () => setIsFailureModalOpen(false)

  return (
    <RedeemCodeWrapper>
      <RedeemCodeCardForm onSubmit={handleVerifyCode}>
        <RedeemCodeFlexGap
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          <img src="/logos/mootaeworld-logo.svg" alt="mootaeworld-logo" width={120} />

          <AppPrimaryText fontSize="1.25em">{title}</AppPrimaryText>

          <RedeemCodeInputWrapper>
            <RedeemCodeInput
              placeholder="กรุณากรอก Code redeem"
              value={redeemCode}
              onChange={handleRedeemCodeChange}
            />
          </RedeemCodeInputWrapper>
        </RedeemCodeFlexGap>

        <AppPrimaryButton variant="contained" style={{ width: '11.25em' }} type="submit">
          ยืนยัน
        </AppPrimaryButton>

        <SocialLinkGroup />
      </RedeemCodeCardForm>

      <FailureModal open={isFailureModalOpen} title="ขออภัย!" onClose={onCloseFailureModal}>
        <>
          ไม่สามารถ Redeem รับวอลเปเปอร์ได้ <div>เนื่องจากสิทธิ์ ไม่ถูกต้องหรือมีการลงทะเบียนแล้ว</div>
        </>
      </FailureModal>
    </RedeemCodeWrapper>
  )
}

export default RedeemCodePage
