import styled from 'styled-components'

export const ModalContent = styled.div`
  padding: 1rem;
  background-color: #f2f4f7;
  border-radius: 0.75rem;
  max-height: calc(100vh - 13em);
  overflow-y: auto;

  & h2,
  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1.25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: calc(100% - 2rem);
  max-width: 600px;
  background-color: white;
`
