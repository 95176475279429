import styled from 'styled-components'
import { FlexGap } from '../../App.styled'
import { FormTextInput } from '../../components/SchemaForm/SchemaForm.styled'

export const RedeemCodeWrapper = styled.div`
  width: 100%;
`

export const RedeemCodeFlexGap = styled(FlexGap)`
  gap: 1.5em;

  @media only screen and (min-width: 768px) {
    gap: 2em;
  }
`

export const RedeemCodeCardForm = styled.form`
  background-image: url('/images/redeem-card.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 3em);
  margin-bottom: -7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;

  @media only screen and (min-width: 375px) {
    gap: 4em;
  }

  @media only screen and (min-width: 768px) {
    gap: 6em;
  }
`

export const RedeemCodeInputWrapper = styled.div`
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  max-width: 600px;
`

export const RedeemCodeInput = styled(FormTextInput)`
  border: 1px solid #e1b87a;
  text-align: center;
`
