import Checkbox from '@mui/material/Checkbox/Checkbox'
import React from 'react'
import { FlexGap } from '../../App.styled'
import { FormCheckboxProps } from './FormCheckbox.model'
import { CheckboxIcon, CheckedCheckboxIcon } from './FormCheckbox.styled'

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  formId,
  value,
  label,
  error = false,
  onChange,
}) => (
  <FlexGap id={formId} alignItems="baseline">
    <Checkbox
      checked={value === 'true'}
      icon={<CheckboxIcon error={error} />}
      checkedIcon={<CheckedCheckboxIcon />}
      onChange={e => onChange(e.target.checked ? 'true' : 'false')}
      sx={{ padding: 0 }}
    />
    {label && <span>{label}</span>}
  </FlexGap>
)

export default FormCheckbox
