import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup/RadioGroup'
import React from 'react'
import { ControlOption } from '../SchemaForm/SchemaForm.model'
import { FormRadioGroupProps } from './FormRadioGroup.model'
import { FormRadio, RadioIcon } from './FormRadioGroup.styled'

const renderLabel = (controlOption: ControlOption) =>
  controlOption.labelImageSrc ? (
    <img src={controlOption.labelImageSrc} alt={`${controlOption.label}-${controlOption.value}`} />
  ) : (
    controlOption.label || controlOption.value
  )

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  formField,
  formId,
  value = '',
  onControlChange,
  onRadioGroupChange,
}) => (
  <RadioGroup
    id={formId}
    sx={{
      display: 'grid',
      gridTemplateColumns: `repeat(${
        formField.properties?.radioColumnsLength || 2
      }, minmax(0, 1fr))`,
      columnGap: '.5em',
      rowGap: '1em',
    }}
    onChange={event => onRadioGroupChange(event.target.value)}
    value={value}
  >
    {formField.properties?.controlOptions?.map(data => (
      <FormControlLabel
        onChange={() => onControlChange(data)}
        key={`control-${data.label}-${data.value}`}
        value={data.value}
        control={<FormRadio icon={<RadioIcon />} checkedIcon={<RadioIcon checked />} />}
        label={renderLabel(data)}
        sx={{ alignItems: 'flex-start', gap: '.5em', margin: '0' }}
      />
    ))}
  </RadioGroup>
)

export default FormRadioGroup
