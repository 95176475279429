import styled from 'styled-components'
import { AppText, FlexGap } from '../App.styled'

export const ViewOrderFlexColumn = styled.div<{
  justifyContent?: string
  alignItems?: string
  width?: string
  flexDirection?: string
}>`
  display: flex;
  gap: 1em;

  justify-content: ${props => props.justifyContent || 'space-between'};

  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
`

export const ViewOrderLabel = styled(AppText)`
  color: #667085;
  white-space: nowrap;
`

export const ViewOrderText = styled(AppText)<{ wordBreak?: string }>`
  word-break: ${props => props.wordBreak || 'break-all'};
`

export const ViewOrderCard = styled(FlexGap)`
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  padding: 1.5em 1em;
`
