import Button from '@mui/material/Button/Button'
import styled from 'styled-components'

export const AppPrimaryButton = styled(Button)`
  border-radius: 20px !important;
  background-color: #931720 !important;
  font-weight: bold !important;
  font-size: 1rem !important;
  padding: 0.625em 1em !important;
  &:hover {
    background-color: #931720 !important;
  }
  &:disabled {
    background-color: #f2f4f7 !important;
    color: #667085 !important;
  }
`

export const AppOutlinePrimary = styled(Button)`
  border-radius: 20px !important;
  color: #931720 !important;
  background-color: white !important;
  font-weight: 700 !important;
  padding: 0.625em 1em !important;

  &:hover {
    color: #d34f34 !important;
  }
`

export const AppPrimaryText = styled.span<{
  fontWeight?: number | `${number}`
  fontSize?: string
  variant?: 'primary'
}>`
  font-weight: ${props => props.fontWeight || 700};
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.variant === 'primary' && 'color: #931720;'}
`

export const AppText = styled.span<{
  color?: string
  fontSize?: string
  fontWeight?: string
  required?: boolean
  maxWidth?: string
  variant?: 'danger'
}>`
  font-size: ${props => props.fontSize || '1em'};
  ${props => props.variant === 'danger' && 'color: #f04438;'}
  ${props => props.color && `color: ${props.color};`}
  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`};
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`};
  ${props =>
    props.required &&
    `&:after {
    content: '*';
    color: #c2442a;
  }`}
`

export const AppLinkButton = styled.span`
  cursor: pointer;
  color: #c2442a;
  text-decoration: underline;
  transition-property: color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  &:hover {
    color: rgba(194, 68, 42, 0.7);
    text-decoration-color: rgba(194, 68, 42, 0.7);
  }
`

export const AppGradientWrapper = styled.div`
  min-height: 100vh;
`

export const AppContainer = styled.div`
  max-width: 39.5em;
  margin: auto;
  padding: 1.5em 1em;
`

export const AppFormFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`

export const AppFormCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em 1em;
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  width: 100%;
  box-shadow: 0px 0px 4px -2px rgba(16, 24, 40, 0.06), 0px 2px 8px -2px rgba(16, 24, 40, 0.1);
`

export const AppDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d0d5dd;
`

export const FlexGap = styled.div<{
  flexDirection?: 'column' | string
  gap?: string
  justifyContent?: string
  alignItems?: 'start' | 'center' | 'baseline' | string
  width?: string
}>`
  display: flex;
  ${props => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${props => props.alignItems && `align-items: ${props.alignItems};`}
  ${props => props.width && `width: ${props.width};`}
  gap: ${props => props.gap || '1em'};
`

export const LoadingOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: 'white',
  opacity: '0.7',
  width: '100%',
  height: '100%',
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
