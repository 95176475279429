// export const CreateOrderMutation = `mutation CreateUser(
//   $customerFirstname: String!
//   $customerId: String!
//   $customerLastname: String!
//   $orderDetail: String!
//   $receipt: String!
// ) {
//   createOrder(customer_firstname: $customerFirstname, customer_id: $customerId, customer_lastname: $customerLastname, order_detail: $orderDetail, receipt: $receipt) {
//     customer_firstname
//     customer_id
//     customer_lastname
//     order_detail
//     order_id
//     order_status
//     receipt
//   }
// }`

export const CreateOrderMutation = `mutation CreateOrderMutation(
  $acceptTerms: String!,
  $allowNewsletter: String!,
  $choice: String!,
  $collection: String!,
  $customCircle: String!,
  $customerFirstname: String!,
  $customerLastname: String!,
  $dateOfBirth: String!,
  $dateOfBirth2: String,
  $discountCode: String,
  $disease: String!,
  $email: String!,
  $gender: String!,
  $genderText: String,
  $paySlip: String,
  $remark: String,
  $saleChannel: String!,
  $saleUsername: String!,
  $startTimeUse: String!,
  $subChoice1: String,
  $subChoice2: String,
  $telephone: String!,
  $template: String,
  $timeOfBirth: String!,
  $paymentId: String,
  $orderId: String
) {
  createOrder(input: {allow_newsletter: $allowNewsletter,
    choice: $choice,
    collection: $collection,
    custom_circle: $customCircle,
    customer_firstname: $customerFirstname,
    date_of_birth: $dateOfBirth,
    date_of_birth_2: $dateOfBirth2,
    discount_code: $discountCode,
    disease: $disease,
    email: $email,
    gender_text: $genderText,
    pay_slip: $paySlip,
    sale_channel: $saleChannel,
    sale_username: $saleUsername,
    sub_choice_1: $subChoice1,
    sub_choice_2: $subChoice2,
    telephone: $telephone,
    template: $template,
    time_of_birth: $timeOfBirth,
    accept_terms: $acceptTerms,
    customer_lastname: $customerLastname,
    gender: $gender,
    remark: $remark,
    payment_id: $paymentId,
    order_id: $orderId,
    start_time_use: $startTimeUse}) {
    accept_terms
    allow_newsletter
    choice
    collection
    custom_circle
    customer_firstname
    customer_id
    customer_lastname
    date_created
    date_of_birth
    disease
    email
    gender
    gender_text
    itemized_pricing {
      items {
        currency
        item_name
        price
        type
      }
      total
    }
    order_id
    order_status
    pay_slip
    payment_id
    remark
    sale_channel
    sale_username
    start_time_use
    sub_choice_1
    sub_choice_2
    telephone
    template
    time_of_birth
  }
}
`

export interface CreateOrderMutationResult {
  data: {
    createOrder: {
      accept_terms: string
      allow_newsletter: string
      choice: string
      collection: string
      custom_circle: string
      customer_firstname: string
      customer_id: string
      customer_lastname: string
      date_created: string
      date_of_birth: string
      disease: string
      email: string
      gender: string
      gender_text: string
      itemized_pricing: {
        items: {
          currency: string
          item_name: string
          price: number
          type: string
        }[]
        total: number
      }
      order_id: string
      order_status: string
      pay_slip: string
      payment_id: string
      remark: string
      sale_channel: string
      sale_username: string
      start_time_use: string
      sub_choice_1: string
      sub_choice_2: string
      telephone: string
      template: string
      time_of_birth: string
    }
  }
}
