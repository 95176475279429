export const GetOrderQuery = `query GetOrder(
  $customerId: String!,
  $orderId: String!
) {
  getOrder(
    customer_id: $customerId,
    order_id: $orderId
  ) {
    choice
    itemized_pricing {
      items {
        item_name
        price
        type
      }
      total
    }
    order_status
    order_id
    date_created
    customer_firstname
    customer_lastname
    telephone
    email
    sale_channel
    sale_username
    collection
    template
    sub_choice_1
    sub_choice_2
    img_url_1
    start_time_use_datetime
    lucky_number {
      definition
      number
    }
    internal_order_status
  }
}
`

export interface GetOrderQueryResult {
  data: {
    getOrder: {
      choice: string
      itemized_pricing: {
        items: {
          item_name: string
          price: number
          type: string
        }[]
        total: number
      }
      order_id: string
      order_status: string
      date_created: string
      customer_firstname: string
      customer_lastname: string
      telephone: string
      email: string
      sale_channel: string
      sale_username: string
      collection: string
      template: string
      sub_choice_1: string
      sub_choice_2: string
      img_url_1: string
      start_time_use_datetime: string
      lucky_number : {
        definition: string
        number : string
      }
      internal_order_status : string
    }
  }
}
