export type DiscountErrorMessageValue = keyof typeof DiscountErrorMessage

export interface FormDiscountCodeParams {
  discountCode?: string
  errorMessage?: DiscountErrorMessageValue
  onChangeDiscountCode?: (value: string) => void
  onSubmit: (value: string) => void
}

export enum DiscountErrorMessage {
  INVALID_DISCOUNT_CODE = 'โค้ดส่วนลดไม่สามารถใช้ได้ หรือ หมดอายุ กรุณาลองใช้โค้ดอื่น',
}
