import { API, graphqlOperation } from 'aws-amplify'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FailureModal from '../../components/FailureModal/FaiilureModal.component'
import SchemaForm from '../../components/SchemaForm/SchemaForm.component'
import { FormValue } from '../../components/SchemaForm/SchemaForm.model'
import { VerifyDiscountQuery } from '../../graphql/queries/verifyDiscount.query'
import { RedeemCodeFormPageProps } from './RedeemCodeFormPage.model'

const RedeemCodeFormPage: React.FC<RedeemCodeFormPageProps> = ({
  failNavigateUrl,
  onChangeLoading,
  onSubmit,
  ...restProps
}) => {
  const navigate = useNavigate()
  const { redeemCode } = useParams()
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false)

  const handleCloseFailureModal = () => {
    setIsFailureModalOpen(false)
    navigate(failNavigateUrl)
  }

  const handleVerifyCode = useCallback(async () => {
    try {
      onChangeLoading(true)
      await API.graphql(
        // @TODO: Seperation collection redeem code config as variable
        graphqlOperation(VerifyDiscountQuery, { collection: 'the1', discountCode: redeemCode }),
      )
    } catch {
      setIsFailureModalOpen(true)
    } finally {
      onChangeLoading(false)
    }
  }, [onChangeLoading, redeemCode])

  const handleSubmit = (formValue: FormValue) =>
    onSubmit({ ...formValue, discountCode: redeemCode })

  useEffect(() => {
    handleVerifyCode()
  }, [handleVerifyCode])

  return (
    <>
      <SchemaForm {...restProps} onSubmit={handleSubmit} />
      <FailureModal open={isFailureModalOpen} title="ขออภัย!" onClose={handleCloseFailureModal}>
        <>
          ไม่สามารถ Redeem รับวอลเปเปอร์ได้ <div>เนื่องจากสิทธิ์ ไม่ถูกต้องหรือมีการลงทะเบียนแล้ว</div>
        </>
      </FailureModal>
    </>
  )
}

export default RedeemCodeFormPage
