export const GetOrdersByCustomer = `query GetOrdersByCustomer(
  $email: String!
) {
  getOrdersByCustomer(input: {email: $email}) {
    order_id
    date_created
    collection
    template
    customer_id
    internal_order_status
  }
}
`

export interface SearchOrderResult {
  order_id: string
  date_created: string
  collection: string
  template: string
  customer_id: string
  internal_order_status: string
}

export interface GetOrdersByCustomerResult {
  data: {
    getOrdersByCustomer: SearchOrderResult[]
  }
}
