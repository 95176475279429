import { FlexGap } from '../../App.styled'
import { SocialImage, SocialLink } from './SocialLinkGroup.styled'

const SocialLinkGroup = () => (
  <FlexGap justifyContent="center">
    <SocialLink href="https://www.facebook.com/Mootaeworld" target="_blank" rel="noreferrer">
      <SocialImage src="/email-assets/icon-facebook.png" alt="Facebook" />
    </SocialLink>
    <SocialLink href="https://www.tiktok.com/@mootaeworld" target="_blank" rel="noreferrer">
      <SocialImage src="/email-assets/icon-tiktok.png" alt="TikTok" />
    </SocialLink>
    <SocialLink href="https://lin.ee/T45Wcxb" target="_blank" rel="noreferrer">
      <SocialImage src="/email-assets/icon-line.png" alt="Line" />
    </SocialLink>

    <SocialLink
      href="https://www.youtube.com/channel/UCYMDAlHZETV3xJ3eeDoUyBQ"
      target="_blank"
      rel="noreferrer"
    >
      <SocialImage src="/email-assets/icon-youtube.png" alt="YouTube" />
    </SocialLink>

    <SocialLink href="https://shp.ee/mwhgqw3" target="_blank" rel="noreferrer">
      <SocialImage src="/email-assets/icon-shopee.png" alt="Shopee" />
    </SocialLink>
  </FlexGap>
)

export default SocialLinkGroup
