import Button from '@mui/material/Button/Button'
import Modal from '@mui/material/Modal/Modal'
import React from 'react'
import { AppPrimaryButton, AppText } from '../../App.styled'
import { AppModalProps } from './AppModal.model'
import { ModalWrapper, ModalContent } from './AppModal.styled'

const AppModal: React.FC<AppModalProps> = ({
  hasAcceptButton = false,
  modalContent = '',
  modalTitle,
  open = false,
  onAccept,
  onClose,
}) => (
  <Modal open={open} onClose={onClose}>
    <ModalWrapper>
      <Button variant="text" sx={{ marginLeft: 'auto', minWidth: 'unset' }} onClick={onClose}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 5L5 15M5 5L15 15"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
      <AppText style={{ textAlign: 'center' }} fontWeight="bold">
        {modalTitle}
      </AppText>
      <ModalContent dangerouslySetInnerHTML={{ __html: modalContent }} />
      {hasAcceptButton && (
        <AppPrimaryButton variant="contained" onClick={onAccept}>
          ยอมรับ
        </AppPrimaryButton>
      )}
    </ModalWrapper>
  </Modal>
)

export default AppModal
