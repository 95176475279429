import styled from 'styled-components'

export const SocialLink = styled.a`
  height: 1.5em;

  @media only screen and (min-width: 768px) {
    height: 2.25em;
  }
`

export const SocialImage = styled.img`
  height: 1.5em;

  @media only screen and (min-width: 768px) {
    height: 2.25em;
  }
`
