import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form'
import {
  AppFormCard,
  AppLinkButton,
  AppPrimaryButton,
  AppPrimaryText,
  AppText,
  FlexGap,
} from '../../App.styled'
import AppModal from '../AppModal/AppModal.component'
import FormCheckbox from '../FormCheckbox/FormCheckbox.component'
import FormDateSelectGroup from '../FormDateSelectGroup/FormDateSelectGroup.component'
import FormRadioGroup from '../FormRadioGroup/FormRadioGroup.component'
import FormUploadImage from '../FormUploadImage/FormUploadImage.component'
import SummarySchemaForm from '../SummarySchemaForm/SummarySchemaForm'
import {
  FormValue,
  ControlOption,
  SchemaFormProps,
  FormField,
  DependsOnValue,
  FormProperty,
} from './SchemaForm.model'
import {
  FormControlWrapper,
  FormErrorMessage,
  FormReadonlyInput,
  FormTextInput,
  FormTextInputWrapper,
  FormTextarea,
} from './SchemaForm.styled'
import { SchemaFormUtils } from './SchemaForm.utils'
import { FormSelect } from '../FormDateSelectGroup/FormDateSelectGroup.styled'
import PaymentSubmitForm from '../PaymentSubmitForm/PaymentSubmitForm'

type ResultReduceValue = {
  [key: string]: (boolean | undefined)[]
}

const SchemaForm: React.FC<SchemaFormProps> = ({
  discountCode,
  discountCodeErrorMessage,
  priceQueryData,
  customerId,
  orderId,
  paymentId,
  formDefinition,
  onChangeDiscountCode,
  onGetPrice,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit: handleFormSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormValue>({
    mode: 'onChange',
    defaultValues: formDefinition.defaultValues,
  })

  const [currentFormValue, setCurrentFormValue] = useState<FormValue>()
  const [pageIndex, setPageIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false)
  const [policyModalTitle, setPolicyModalTitle] = useState('')
  const [policyModalContent, setPolicyModalContent] = useState('')
  const [policyFieldName, setPolicyFieldName] = useState('')
  const [disabledFields, setDisabledFields] = useState<string[]>([])

  const currentPageFormFields = useMemo(
    () => formDefinition.fields.filter(value => value.pageIndex === pageIndex),
    [formDefinition.fields, pageIndex],
  )

  const currentDependsOnValues = useMemo(
    () =>
      currentPageFormFields
        .filter(value => !!value.dependsOn)
        .map(({ dependsOn, name }) => ({
          name,
          dependsOn,
        })),
    [currentPageFormFields],
  )

  const currentDependsOnFieldNames = useMemo(
    () => currentDependsOnValues.map(data => data.name),
    [currentDependsOnValues],
  )

  const isSummaryPage = useMemo(
    () => pageIndex === formDefinition.numberOfPage,
    [formDefinition.numberOfPage, pageIndex],
  )

  const isMatchDependsOn = useCallback(
    (dependsOn: DependsOnValue) =>
      currentFormValue &&
      Object.keys(dependsOn).every(
        dependsOnKey =>
          currentFormValue[dependsOnKey] &&
          dependsOn[dependsOnKey].includes(currentFormValue[dependsOnKey] || ''),
      ),
    [currentFormValue],
  )

  const isFormFieldDisabled = useCallback(
    (formField: FormField) => disabledFields.includes(formField.name),
    [disabledFields],
  )

  const isRequired = useCallback(
    (formField: FormField) =>
      formField.type === 'policyCheckbox' ||
      (!isFormFieldDisabled(formField) && formField.required),
    [isFormFieldDisabled],
  )

  const isShowErrorMessage = useCallback(
    (formField: FormField) =>
      !isFormFieldDisabled(formField) && formField.type !== 'policyCheckbox',
    [isFormFieldDisabled],
  )

  const handleOpenModal = (controlOption: ControlOption) => {
    if (!controlOption.modalData) {
      return
    }

    setModalTitle(controlOption.modalData.title)
    let imageModalSize = '150'
    const collection = formDefinition.defaultValues?.collection;

    if (collection === 'energetic_embracing') imageModalSize = '100%'

    let imageModalContent = `<div style="display: flex;justify-content: center;align-items: center;margin-bottom: 2vh"><img src="/images/collections/${collection}/${controlOption.value}.png" alt="collection-example-art" width="${imageModalSize}" "/></div>`
    imageModalContent += controlOption.modalData.content;

    setModalContent(imageModalContent)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalTitle('')
    setModalContent('')
    setIsModalOpen(false)
  }

  const handleOpenPolicyModal = (
    fieldName: string,
    policyModalData?: Pick<FormProperty, 'policyModalData'>,
  ) => {
    if (!policyModalData || !policyModalData.policyModalData) {
      return
    }

    setPolicyFieldName(fieldName)
    setPolicyModalTitle(policyModalData.policyModalData?.title)
    setPolicyModalContent(policyModalData.policyModalData?.content)
    setIsPolicyModalOpen(true)
  }

  const handleClosePolicyModal = () => {
    setPolicyFieldName('')
    setPolicyModalTitle('')
    setPolicyModalContent('')
    setIsPolicyModalOpen(false)
  }

  const handleAcceptPolicy = () => {
    setValue(policyFieldName, 'true')
    handleClosePolicyModal()
  }

  const handleFormDepedsOn = useCallback(
    (currentFieldName: string, formValue: FormValue) => {
      if (!formValue) {
        return
      }

      const resultDependsOnValues = currentDependsOnValues.reduce<ResultReduceValue>((acc, cur) => {
        if (currentFieldName === cur.name || !formValue[cur.name] || !cur.dependsOn) {
          return { ...acc }
        }

        if (!acc[cur.name]) {
          return { ...acc, [cur.name]: [isMatchDependsOn(cur.dependsOn)] }
        }

        return { ...acc, [cur.name]: [...acc[cur.name], isMatchDependsOn(cur.dependsOn)] }
      }, {})

      Object.keys(resultDependsOnValues).forEach(key => {
        if (resultDependsOnValues[key].every(data => !data)) {
          setValue(key, undefined)
        }
      })
    },
    [currentDependsOnValues, isMatchDependsOn, setValue],
  )

  const handleDisableTime = (
    disabled: boolean,
    fieldName: string,
    onFormChange: (...event: unknown[]) => void,
  ) => {
    onFormChange('')
    setDisabledFields(prev =>
      disabled ? [...prev, fieldName] : prev.filter(data => data !== fieldName),
    )
  }

  const handleChangePage = (newPageIndex: number) => setPageIndex(newPageIndex)

  const handleSubmitPage: SubmitHandler<FormValue> = data => {
    const errorRadioResult = SchemaFormUtils.errorRadioDependsOn(formDefinition, data)

    clearErrors(currentDependsOnFieldNames)

    if (errorRadioResult.length > 0) {
      errorRadioResult.forEach(fieldName =>
        setError(fieldName, {
          type: 'required',
          message: SchemaFormUtils.defaultRequiredErrorText,
        }),
      )

      return
    }

    if (isSummaryPage) {
      return onSubmit(
        SchemaFormUtils.mapSubmitDefaultEmptyDataValue(
          formDefinition,
          SchemaFormUtils.mapSubmitImageDataValue(formDefinition, data),
        ),
      )
    }

    if (pageIndex === formDefinition.numberOfPage - 1) {
      onGetPrice(data, formDefinition)
    }

    setPageIndex(prev => prev + 1)
  }

  const renderField = useCallback(
    (
      formField: FormField,
      value: string,
      onChange: (...event: unknown[]) => void,
      error: FieldError | undefined,
    ) => {
      if (formField.type === 'radio') {
        return (
          <FormRadioGroup
            formId={SchemaFormUtils.mapFormId(formField)}
            value={value}
            formField={formField}
            onControlChange={handleOpenModal}
            onRadioGroupChange={onChange}
          />
        )
      }

      if (formField.type === 'image') {
        return (
          <FormUploadImage
            formId={SchemaFormUtils.mapFormId(formField)}
            formField={formField}
            onChange={onChange}
            value={value}
          />
        )
      }

      if (formField.type === 'readonlyInput') {
        return <FormReadonlyInput>{formField.properties?.readonlyInputValue}</FormReadonlyInput>
      }

      if (SchemaFormUtils.isTextarea(formField)) {
        return (
          <FormTextInputWrapper error={!!error}>
            <FlexGap flexDirection="column" gap=".5em">
              <FormTextarea
                rows={formField.properties?.numberOfLines}
                error={!!error}
                id={SchemaFormUtils.mapFormId(formField)}
                placeholder={formField.properties?.placeholder || `กรุณากรอก${formField.title}`}
                value={value}
                onChange={e => onChange(e.target.value)}
                maxLength={formField.properties?.textMaxLength}
              />
              {SchemaFormUtils.renderTextMaxLength(formField, value)}
            </FlexGap>
          </FormTextInputWrapper>
        )
      }

      if (['email', 'textInput', 'tel'].includes(formField.type)) {
        return (
          <FormTextInputWrapper error={!!error}>
            <FormTextInput
              error={!!error}
              id={SchemaFormUtils.mapFormId(formField)}
              type="text"
              hasValue={!!value}
              placeholder={formField.properties?.placeholder || `กรุณากรอก${formField.title}`}
              value={value}
              onChange={e => onChange(e.target.value)}
              inputMode={SchemaFormUtils.mapInputMode(formField)}
              maxLength={formField.properties?.textMaxLength}
            />
          </FormTextInputWrapper>
        )
      }

      if (formField.type === 'checkbox') {
        return (
          <FlexGap alignItems="flex-start" gap=".5em">
            <FormCheckbox
              formId={SchemaFormUtils.mapFormId(formField)}
              error={!!error}
              value={value}
              onChange={onChange}
            />
            <span>{formField.properties?.checkboxLabel}</span>
          </FlexGap>
        )
      }

      if (formField.type === 'policyCheckbox') {
        return (
          <FlexGap alignItems="flex-start" gap=".5em">
            <FormCheckbox
              formId={SchemaFormUtils.mapFormId(formField)}
              error={!!error}
              value={value}
              onChange={onChange}
            />
            <span>
              ฉันอ่านและยอมรับ{' '}
              <AppLinkButton
                onClick={() => handleOpenPolicyModal(formField.name, formField.properties)}
              >
                ข้อตกลงและเงื่อนไขการใช้บริการ
              </AppLinkButton>{' '}
              ดังกล่าวของ มูเตเวิร์ล
            </span>
          </FlexGap>
        )
      }

      if (formField.type === 'time') {
        return (
          <FormTextInputWrapper error={!isFormFieldDisabled(formField) && !!error}>
            <FlexGap flexDirection="column" gap=".75em">
              <FormTextInput
                error={!isFormFieldDisabled(formField) && !!error}
                id={SchemaFormUtils.mapFormId(formField)}
                type="time"
                formType="time"
                hasValue={!!value}
                value={value}
                disabled={isFormFieldDisabled(formField)}
                onChange={e => onChange(e.target.value)}
              />
              {formField.properties?.hasDisableTime && (
                <FlexGap alignItems="flex-start" gap=".5em">
                  <FormCheckbox
                    value={isFormFieldDisabled(formField) ? 'true' : undefined}
                    onChange={checkboxValue =>
                      handleDisableTime(checkboxValue === 'true', formField.name, onChange)
                    }
                  />
                  <span>{formField.properties?.disableTimeCheckboxLabel}</span>
                </FlexGap>
              )}
            </FlexGap>
          </FormTextInputWrapper>
        )
      }

      if (formField.type === 'date') {
        return (
          <FormDateSelectGroup
            formId={SchemaFormUtils.mapFormId(formField)}
            formField={formField}
            onChange={onChange}
            error={!!error}
            value={value}
          />
        )
      }

      if (formField.type === 'select') {
        const formId = SchemaFormUtils.mapFormId(formField)
        const selectDefaultValue = 'DEFAULT'

        return (
          <FormSelect
            name={formId}
            id={formId}
            value={value || selectDefaultValue}
            hasValue={!!value}
            error={!!error}
            onChange={onChange}
          >
            <option disabled hidden value={selectDefaultValue}>
              {formField.properties?.placeholder}
            </option>
            {formField.properties?.controlOptions?.map(option => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </FormSelect>
        )
      }

      return null
    },
    [isFormFieldDisabled], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const renderControl = useCallback(
    (formField: FormField) => (
      <Controller
        name={formField.name}
        control={control}
        rules={{
          required: isRequired(formField) ? SchemaFormUtils.defaultRequiredErrorText : false,
          pattern: SchemaFormUtils.mapValidatePattern(formField),
          validate:
            isRequired(formField) && ['checkbox', 'policyCheckbox'].includes(formField.type)
              ? value => value === 'true'
              : undefined,
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControlWrapper>
            {renderField(formField, value || '', onChange, error)}
            {isShowErrorMessage(formField) && error && (
              <FormErrorMessage>{error.message}</FormErrorMessage>
            )}
          </FormControlWrapper>
        )}
      />
    ),
    [control, isRequired, isShowErrorMessage, renderField],
  )

  const renderFormFields = useCallback(
    (formField: FormField, formFieldIndex: number, keyPageIndex: number, keyCardIndex: number) =>
      (!formField.dependsOn || isMatchDependsOn(formField.dependsOn)) && (
        <FlexGap
          key={`field-${keyPageIndex}-${keyCardIndex}-${formFieldIndex}`}
          flexDirection="column"
          gap={formField.type === 'readonlyInput' ? '0.5em' : '1em'}
        >
          {(formField.title || formField.subtitle || formField.description) && (
            <FlexGap gap="0" flexDirection="column">
              {(formField.title || formField.remark || formField.subtitle) && (
                <FlexGap flexDirection="column">
                  {SchemaFormUtils.renderFieldTitle(formField)}
                  {SchemaFormUtils.renderFieldRemark(formField)}
                  {SchemaFormUtils.renderFieldSubtitle(formField)}
                </FlexGap>
              )}
              {SchemaFormUtils.renderFieldDescription(formField)}
            </FlexGap>
          )}

          <FlexGap flexDirection="column" gap=".5em">
            {SchemaFormUtils.renderFieldLabel(formField)}
            {renderControl(formField)}
          </FlexGap>

          {SchemaFormUtils.renderFieldFooter(formField)}
        </FlexGap>
      ),
    [isMatchDependsOn, renderControl],
  )

  const mapFilterCardIndex = useCallback(
    (formFields: FormField[], cardIndex: number) =>
      formFields
        .filter(formField => formField.cardIndex === cardIndex)
        .map((formField, formFieldIndex) =>
          renderFormFields(formField, formFieldIndex, pageIndex, cardIndex),
        ),
    [pageIndex, renderFormFields],
  )

  const renderFormCards = useCallback(
    (formFields: FormField[], maxCardIndex: number) =>
      Array.from({ length: maxCardIndex + 1 }, (_, cardIndex) =>
        SchemaFormUtils.isHideCardBackground(formDefinition, pageIndex, cardIndex) ? (
          mapFilterCardIndex(formFields, cardIndex)
        ) : (
          <AppFormCard key={`form-card-${cardIndex}`}>
            {SchemaFormUtils.renderCardTitle(formDefinition, pageIndex, cardIndex)}
            {mapFilterCardIndex(formFields, cardIndex)}
          </AppFormCard>
        ),
      ),
    [formDefinition, mapFilterCardIndex, pageIndex],
  )

  const renderSubmitButton = useMemo(() => {
    const summaryButtonText =
      formDefinition.paymentMethod === 'PAY_SOLUTION' ? 'ชำระเงิน' : 'ยืนยันคำสั่งซื้อ'

    const submitText = isSummaryPage
      ? summaryButtonText
      : formDefinition.numberOfPage - 1 === pageIndex
      ? 'บันทึกข้อมูล'
      : 'ถัดไป'

    return (
      <AppPrimaryButton variant="contained" type="submit">
        {submitText}
      </AppPrimaryButton>
    )
  }, [formDefinition.numberOfPage, formDefinition.paymentMethod, isSummaryPage, pageIndex])

  const renderPage = useMemo(
    () =>
      !isSummaryPage ? (
        <FlexGap flexDirection="column" alignItems="center" gap="1.5em">
          <FlexGap flexDirection="column" alignItems="center" gap=".25em">
            <AppPrimaryText fontSize="1.5rem">{formDefinition.formTitle}</AppPrimaryText>
            <AppText style={{ textAlign: 'center', padding: '0 3.5em' }}>
              {formDefinition.formDescription}
            </AppText>
          </FlexGap>
          {renderFormCards(
            currentPageFormFields,
            SchemaFormUtils.mapMaxCardIndex(currentPageFormFields),
          )}
        </FlexGap>
      ) : (
        currentFormValue && (
          <SummarySchemaForm
            discountCodeErrorMessage={discountCodeErrorMessage}
            priceQueryData={priceQueryData}
            formDefinition={formDefinition}
            formValue={currentFormValue}
            discountCode={discountCode}
            onChangeDiscountCode={onChangeDiscountCode}
            onChangePage={handleChangePage}
            onGetPrice={onGetPrice}
          />
        )
      ),
    [
      currentFormValue,
      currentPageFormFields,
      discountCode,
      discountCodeErrorMessage,
      formDefinition,
      isSummaryPage,
      onChangeDiscountCode,
      onGetPrice,
      priceQueryData,
      renderFormCards,
    ],
  )

  useEffect(() => {
    const subscription = watch((formValue, { name }) => {
      setCurrentFormValue({ ...formValue })
      if (name) {
        handleFormDepedsOn(name, { ...formValue })
      }
    })

    return () => subscription.unsubscribe()
  }, [handleFormDepedsOn, watch])

  return (
    <PaymentSubmitForm
      currentFormValue={currentFormValue}
      priceQueryData={priceQueryData}
      customerId={customerId}
      orderId={orderId}
      paymentId={paymentId}
      formDefinition={formDefinition}
      isSummaryPage={isSummaryPage}
      handleSubmit={handleFormSubmit(handleSubmitPage, SchemaFormUtils.onInvalid)}
    >
      {renderPage}

      {renderSubmitButton}

      <AppModal
        open={isModalOpen}
        onClose={handleCloseModal}
        modalContent={modalContent}
        modalTitle={modalTitle}
      />
      <AppModal
        hasAcceptButton
        open={isPolicyModalOpen}
        onClose={handleClosePolicyModal}
        onAccept={handleAcceptPolicy}
        modalContent={policyModalContent}
        modalTitle={policyModalTitle}
      />
    </PaymentSubmitForm>
  )
}

export default SchemaForm
