import styled from 'styled-components'

export const CheckboxIcon = styled.div<{ error?: boolean }>`
  background-color: white;
  width: 1.25em;
  height: 1.25em;
  border-radius: 4px;
  border: 1px solid #d0d5dd;

  ${props => props.error && 'border: 1px solid #FDA29B;'}
`

export const CheckedCheckboxIcon = styled.div`
  background-color: #931720;
  border-color: #931720;
  width: 1.25em;
  height: 1.25em;
  border-radius: 4px;

  &:after {
    content: url('/icons/checked-icon.svg');
    position: absolute;
    left: 3px;
  }
`
