import { PriceCurrencyType } from '../graphql/queries/getPrice.query'

export class NumberUtils {
  static numberWithComma = (value: number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  static mapCurrencyText = (currency: PriceCurrencyType, priceText: string) => {
    if (currency === 'THB') {
      return `฿${priceText}`
    }

    if (currency === 'PERCENT') {
      return `${priceText}%`
    }

    return `${priceText} ${currency}`
  }
}
