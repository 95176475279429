import styled from 'styled-components'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
  width: 100%;
  min-height: calc(100vh - 12em);
`

export const FormControlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`

export const FormErrorMessage = styled.div`
  font-size: 0.875rem;
  color: #f04438;
`

export const FieldDescription = styled.div`
  font-size: 0.875rem;
  color: #667085;
`

export const FormReadonlyInput = styled.div`
  color: #931720;
  font-weight: 700;
`

export const FormTextInput = styled.input<{
  error?: boolean
  formType?: 'time'
  hasValue?: boolean
}>`
  font-size: 1rem;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 20px;
  padding: 0.625em 1em;
  outline: none;

  &,
  ::placeholder {
    font-family: 'LINESeedSans', sans-serif;
  }

  ::placeholder {
    color: #667085;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${props => props.error && 'border: 1px solid #FDA29B;'}
  ${props =>
    props.formType === 'time' &&
    `
    height: 2.8125em;
    appearance: none;
    &:disabled, &:disabled:before {
      background-color: #EAECF0;
    }
  `}
  ${props =>
    props.formType === 'time' &&
    !props.hasValue &&
    `
    &:before {
      content: 'hh:mm';
      position: absolute;
      background-color: white;
    }

    &:focus:before {
      width: 0;
      content: '';
    }
  `}

  ${props =>
    props.error &&
    props.formType === 'time' &&
    `
    &[type="time"]::-webkit-calendar-picker-indicator {
      background: none;
  }`}
`

export const FormTextarea = styled.textarea<{ error?: boolean }>`
  font-size: 1rem;
  width: 100%;
  border: 1px solid #d0d5dd;
  border-radius: 20px;
  padding: 0.625em 1em;
  outline: none;

  &,
  ::placeholder {
    font-family: 'LINESeedSans', sans-serif;
  }

  ::placeholder {
    color: #667085;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:disabled {
    background-color: #eaecf0;
  }

  ${props => props.error && 'border: 1px solid #FDA29B;'}
}
`

export const FormTextInputWrapper = styled.div<{ error?: boolean }>`
  position: relative;
  ${props =>
    props.error &&
    `
  &:after {
    content: url("/icons/input-error-icon.svg");
    position: absolute;
    top: 0.8125em;
    right: 1em;
  }
  `}
`
